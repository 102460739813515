<template>
  <div>
    <div class="export-pay-history">
        <b-spinner v-if="exportProcessing"></b-spinner>
        <b-button v-else @click="exportAllTypes" variant="outline-primary">Export</b-button>
    </div>
    <b-spinner v-if="payHistoryProcessing" variant="primary"></b-spinner>
    <b-form v-else validated>
      <b-form-group
        id="upload-pay-history"
        label="Pay History File: "
        label-for="file-pay-history"
      >
        <b-form-file
          id="file-pay-history"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          v-model="payHistoryFile"
          required
        >
        </b-form-file>
      </b-form-group>

      <b-button variant="primary" @click="uploadPayHistory" :disabled="!payHistoryFile">
        <b-icon icon="cloud-upload-fill"></b-icon> Upload
      </b-button>
    </b-form>
    <div v-if="batchList.length < 1">No uploads yet</div>
    <b-table v-else class="mt-4" stacked="md" striped hover :fields="batchListFields" :items="batchList">
      <template #cell(actions)="row">
        <b-button variant="secondary" @click="deletePayHistoryBatch(row)">
          <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import requestCommon from "../../../components/requestCommon.vue";

export default {
  mixins: [requestCommon],

  computed: {
    ...mapGetters({
      adminClient: "api/adminClient",
    }),
  },

  data() {
    return {
      payHistoryFile: null,
      payHistoryProcessing: false,

      exportProcessing: false,

      batchListProcessing: true,
      batchList: [],

      batchListFields: [
        { key: "upload_date", label: "Upload Date" },
        { key: "upload_file", label: "File Name" },
        { key: "actions", label: "Delete" },
        { key: "batch_id", thClass: "d-none", tdClass: "d-none" },
      ],
    };
  },

  methods: {
    async uploadPayHistory() {
      const formData = new FormData();
      formData.append("file", this.payHistoryFile);
      this.payHistoryProcessing = true;

      try {
        await this.adminClient.post("/pay-history/batches", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        this.toastActionStatus({
          title: "Upload",
          message: "success",
          variant: "success",
          delay: 3000,
        });
      } catch (error) {
        this.handleRequestError(error, `[ ${this.payHistoryFile.name} ]`);
      }

      await this.fetchPayHistoryBatches();
      this.payHistoryFile = null;
      this.payHistoryProcessing = false;
    },

    async fetchPayHistoryBatches() {
      this.batchListProcessing = true;

      try {
        const {
          data: { batches },
        } = await this.adminClient.get("/pay-history/batches");
        this.batchList = batches.map((row) => ({
          ...row,
          upload_date: moment
            .utc(row.upload_date)
            .tz(moment.tz.guess())
            .format("M-D-YYYY hh:mm:ss a"),
        }));
      } catch (error) {
        this.handleRequestError(error);
      }

      this.batchListProcessing = false;
    },

    async deletePayHistoryBatch(row) {
      const {
        item: { upload_file: uploadFile, batch_id: batchId },
        index,
      } = row;

      if (confirm(`are you sure you want to delete ${uploadFile}`)) {
        try {
          await this.adminClient.delete(`/pay-history/batches/${batchId}`);
          this.batchList.splice(index, 1);
          this.toastActionStatus({
            title: "Delete",
            message: "success",
            variant: "success",
            delay: 3000,
          });
        } catch (error) {
          this.handleRequestError(error);
        }
      }
    },

    async exportAllTypes() {
      this.exportProcessing = true;
      let types = null;
      try {
        const resp = await this.adminClient.get('pay-history/product-types');
        types = resp.data;
      } catch (error) {
        console.log(error);
        this.handleRequestError(error);
      }

      if (types) {
        await Promise.all(
          Array.from(types, async (t) => this.downloadExport(t), this)
        );
      }
      this.exportProcessing = false;
    },

    async downloadExport(type) {
      try {
        const resp = await this.adminClient.get(
          `pay-history-export/${type}`,
          {responseType: 'blob'}
        );
        const found = resp.headers["content-disposition"].match(/;\s*filename\s*=\s*(?<name>.+)/);
        const fileName = found.groups.name;

        // html anchor element to click
        const link = document.createElement('a');

        // file link in browser's memory
        link.href = URL.createObjectURL(resp.data);

        // link.download = label;
        link.setAttribute('download', fileName);
        link.click();

        // cleanup
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.log(error);
        this.handleRequestError(error);
      }
    },

    handleRequestError(error, preMsg) {
      console.error(error);
      const respStatus = error.response?.status;
      const errorMessages = {
        [respStatus]: "request failed for an unknown reason",
        400: "there was something wrong with your request",
        401: "it appears your session is expired. Log in again",
        403: "you do not have access to upload files",
        409: "duplicate entry",
        500: "unexpected server error",
        404: "user not found",
      };
      const errorMessage = errorMessages[respStatus];
      this.toastActionStatus({
        title: "Request failed",
        message: `${preMsg} Error ${errorMessage}: ${JSON.stringify(error?.response?.data)}`,
        variant: "danger",
        delay: 15000,
      });
    },

    toastActionStatus({ title, message, variant }) {
      this.$root.$bvToast.toast(message, {
        title: title || "",
        variant: variant || "primary",
        noAutoHide: true,
        solid: true,
      });
    },
  },

  mounted() {
    this.fetchPayHistoryBatches();
  }
};
</script>

<style>
.export-pay-history {
  display: grid;
  justify-content: end;
}
</style>