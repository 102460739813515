<template>
  <div><Lineitems :lineitems="lineitems"></Lineitems></div>
</template>

<script>
import { mapGetters } from "vuex";
import Lineitems from "./Lineitems";

export default {
  components: { Lineitems },

  props: { payDate: { type: String, required: true } },

  computed: { ...mapGetters({ authedClient: "api/authedClient" }) },

  data() {
    return { lineitems: [] };
  },

  methods: {
    async fetchLineitems() {
      this.lineitems = (
        await this.authedClient.get("/pay-history/lineitems", {
          params: { pay_date: this.payDate },
        })
      ).data;
    },
  },

  mounted() {
    this.fetchLineitems();
  },
};
</script>
