<template>
  <b-card class="mb-3" header="Bulk actions">
    <b-spinner v-if="userSupervisorsDownloading" variant="primary"></b-spinner>
    <b-button v-else id="downloadButton" @click="downloadUsers" class="my-3"
      >Download user supervisors</b-button
    >
    <hr />
    <b-spinner v-if="usersUploadProcessing" variant="primary"></b-spinner>
    <b-form v-else validated>
      <b-form-group
        id="upload-users"
        label="Upload user supervisors: "
        description="Supported fields are: 'PAYEE EMAIL', 'PAYEE NAME' (new users only),
          'SUPERVISOR' (for any number of supervisors), 'EXTERNAL ID', . If an
          upload includes a 'PAYEE EMAIL' that is not already represented in the system, then a
          new user will be created. Inclusion of a 'PAYEE EMAIL' in the upload indicates that
          you want to replace all current supervisor assignments for that user with those
          specified in the upload. If no `SUPERVISOR` values are given for a user, then the
          user will have no supervisors assigned as a result."
        label-for="file-users"
      >
        <b-form-file
          id="file-users"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          v-model="usersFile"
          required
        >
        </b-form-file>
      </b-form-group>

      <b-button variant="primary" @click="uploadUsers" :disabled="!usersFile">
        <b-icon icon="cloud-upload-fill"></b-icon> Upload
      </b-button>
    </b-form>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import requestCommon from "../../../components/requestCommon";
export default {
  mixins: [requestCommon],
  computed: { ...mapGetters({ adminClient: "api/adminClient" }) },
  data() {
    return {
      usersUploadProcessing: false,
      userSupervisorsDownloading: false,
      usersFile: null,
    };
  },
  methods: {
    async downloadUsers() {
      this.userSupervisorsDownloading = true;
      try {
        const response = await this.adminClient.get("/reports/rep-user-supervisors", {
          responseType: "blob",
        });
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        const timestamp = moment().tz(moment.tz.guess()).format("M-D-YYYY_hh_mm_ss_a");
        fileLink.setAttribute("download", `Users_${timestamp}.xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (error) {
        this.handleRequestError(error);
      }
      this.userSupervisorsDownloading = false;
    },

    async uploadUsers() {
      const formData = new FormData();
      formData.append("file", this.usersFile);
      this.usersUploadProcessing = true;

      try {
        await this.adminClient.put("/reports/rep-user-supervisors", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        this.toastActionStatus({
          title: "Upload",
          message: "success",
          variant: "success",
          delay: 3000,
        });
        this.$emit("needUserListRefresh");
      } catch (error) {
        this.handleRequestError(error, `[ ${this.usersFile.name} ]`);
      }

      this.usersFile = null;
      this.usersUploadProcessing = false;
    },
  },
};
</script>
