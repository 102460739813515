<template>
  <div>
    <CategoryTotals :totals="categoryTotals"></CategoryTotals>
    <b-table
      v-if="paymentValues.length"
      class="pay-table"
      striped
      hover
      stacked="sm"
      sort-by="payDate"
      :fields="fields"
      :items="paymentValues"
    >
      <template #cell(date)="row">
        <router-link :to="{ path: row.item.date }" append>
          <b-button variant="secondary" size="sm"> $ {{ row.item.total }} </b-button>
        </router-link>
      </template>
    </b-table>
    <p v-else>Nothing to show...</p>
  </div>
</template>

<script>
import moment from "moment";
import CategoryTotals from "./Payments/CategoryTotals";

export default {
  components: { CategoryTotals },
  props: {
    payments: { type: Array, required: true },
    categoryTotals: { type: Array, required: true },
  },
  data() {
    return {
      fields: [
        {
          key: "displayDate",
          isRowHeader: true,
          label: "Pay Date",
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "date",
          label: "Pay Breakdown",
          thClass: "text-left",
          tdClass: "text-left",
        },
      ],
    };
  },

  computed: {
    paymentValues() {
      return this.payments.map((payment) => ({
        date: payment.date,
        displayDate: moment(payment.date).format("M/D/YYYY"),
        total: payment.total.toFixed(2),
      }));
    },
  },
};
</script>

<style>
.pay-table td {
  word-wrap: break-word;
}

@media screen and (min-width: 575.98px) {
  .pay-table td {
    word-wrap: inherit;
  }
}
</style>
