export default {
  namespaced: true,
  state: { targetUser: null },

  mutations: {
    setTargetUser(state, targetUser) {
      state.targetUser = targetUser;
    },
  },

  actions: {
    enable({ commit }, { userToken, targetUser }) {
      commit("api/createUserClient", userToken, { root: true });
      commit("setTargetUser", targetUser);
    },

    disable({ commit }) {
      commit("api/clearUserClient", null, { root: true });
      commit("setTargetUser", null);
    },
  },

  getters: {
    targetUser(state) {
      return state.targetUser;
    },
  },
};
