<template>
  <div>
    <b-spinner v-if="loading" variant="primary"></b-spinner>
    <Reps v-else :reps="reps"></Reps>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Reps from "./Reps";

export default {
  components: { Reps },

  computed: { ...mapGetters({ authedClient: "api/authedClient" }) },

  watch: {
    $route(to, from) {
      if (to.params.pendingPayType !== from.params.pendingPayType)
        this.fetchReps(to.params.pendingPayType);
    },
  },

  data() {
    return { reps: [], loading: true };
  },

  methods: {
    async fetchReps() {
      const params = {};

      const pendingPayType = this.$route.params.pendingPayType;

      if (pendingPayType)
        params.pay_type = pendingPayType;

      this.reps = (await this.authedClient.get("/pending-pay/reps", { params })).data;
      this.loading = false;
    },
  },

  mounted() {
    this.fetchReps();
  },
};
</script>