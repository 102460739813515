<template>
  <div>
    <b-input-group size="sm">
      <b-form-input
        id="filter-input"
        v-model="repFilter"
        type="search"
        placeholder="Filter"
      ></b-form-input>
      <b-input-group-append>
        <b-button :disabled="!repFilter" @click="repFilter = ''">Clear</b-button>
      </b-input-group-append>
    </b-input-group>
    <b-table
      v-if="reps.length"
      class="text-center"
      striped
      responsive
      sort-by="name"
      :fields="fields"
      :items="reps"
      :filter="repFilter"
      :filter-included-fields="filterFields"
    >
      <template #cell(viewPay)="row">
        <router-link :to="{ path: row.item.user_id }" append>
          <b-button variant="secondary" size="sm">
            <b-icon icon="person-badge"></b-icon>
          </b-button>
        </router-link>
      </template>
    </b-table>
    <p v-else>No reps to show...</p>
  </div>
</template>

<script>
export default {
  props: { reps: { type: Array, required: true } },

  data() {
    return {
      repFilter: "",
      filterFields: ["name"],
      fields: [
        {
          key: "name",
          label: "Rep",
        },
        {
          key: "viewPay",
          label: "View Pay",
        },
      ],
    };
  },
};
</script>
