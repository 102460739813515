var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"id":"filter-input","type":"search","placeholder":"Filter"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v("Clear")])],1)],1),_c('b-table',{staticClass:"lineitem-table",attrs:{"striped":"","hover":"","stacked":"sm","sort-by":"includePayDate ? 'pay_date' : 'INSTALL DATE'","sort-desc":true,"filter":_vm.filter,"filter-included-fields":_vm.filterFields,"fields":_vm.fields,"items":_vm.lineitemValues},scopedSlots:_vm._u([{key:"cell(TOTAL)",fn:function(row){return [_c('b-button',{attrs:{"variant":"secondary","size":"sm"},on:{"click":() => {
            _vm.hideDetails();
            _vm.openedTable(row);
          }}},[_vm._v(" $ "+_vm._s(row.item.TOTAL)+" ")])]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('b-card-text',{staticClass:"dynamic-row"},[_vm._l((_vm.rows),function(row,index){return _c('b-row',{key:index,staticClass:"w-75 mx-auto mb-2"},[_c('b-col',[_c('b',[_vm._v(_vm._s(row[0]))])]),_c('b-col',{class:{redtext: _vm.enableRedForNegative(row[1])}},[_vm._v(_vm._s(row[1]))])],1)}),_c('b-row',{staticClass:"mb-2"},[_c('b-button',{attrs:{"variant":"secondary","size":"sm"},on:{"click":() => {
                  row.toggleDetails();
                  _vm.rowsClear();
                }}},[_vm._v(" Close ")])],1)],2)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }