<template>
  <div>
    <b-navbar toggleable="sm" type="light">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item
            v-for="item in navLinks"
            :key="item.text"
            class="ml-2"
            active-class="active"
            :to="item.to"
            >{{ item.text }}</b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-breadcrumb v-if="breadcrumbs.length" :items="breadcrumbs"></b-breadcrumb>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { adminViewUserRouteMap } from "../../routes";

export default {
  computed: {
    ...mapGetters({
      userInfo: "auth/effectiveUser",
      authedClient: "api/authedClient",
      lineitemSearch: "search/lineitemSearchStr",
    }),
    isAdminView() {
      return this.$router.currentRoute.matched.find((route) => route.name === "adminViewUser");
    },
    pendingPayLinks() {
      if (this.features?.pending_pay_caliber_types) {
        return [
          { text: "My Solar Pending Pay", to: { name: "pendingPayType", params: {pendingPayType: "solar"} } },
          { text: "My Caliber Smart Pending Pay", to: { name: "pendingPayType", params: {pendingPayType: "calibersmart"} } },
        ];
      } else {
        return [
          { text: "My Pending Pay", to: { name: "pendingPay" } },
        ];
      }
    },
    repsPendingPayLinks() {
      if (this.features?.pending_pay_caliber_types) {
        return [
          { text: "My Reps Solar Pending Pay", to: { name: "repListPendingPayType", params: {pendingPayType: "solar"} } },
          { text: "My Reps Caliber Smart Pending Pay", to: { name: "repListPendingPayType", params: {pendingPayType: "calibersmart"} } },
        ];
      } else {
        return [
          { text: "My Reps Pending Pay", to: { name: "repListPendingPay" } },
        ];
      }
    },
  },

  watch: {
    async $route() {
      this.breadcrumbs = [];
      await this.getRepName();
      this.spreadCrumbs();
    },
    lineitemSearch(newValue) {
      if (newValue) {
        this.searchStr = newValue;
      }
    },
  },

  data() {
    return {
      breadcrumbs: [],
      features: {},
      navLinks: [],
      repName: "",
      searchStr: "",
    };
  },

  methods: {
    setLinks() {
      const links = [];
      if (this.userInfo.roles.find((r) => r === "supervisor")) {
        links.unshift(
           { text: "My Reps Pay", to: { name: "repListPay" } },
           ...this.repsPendingPayLinks,
           // { text: "My Reps Overrides", to: { name: "repsWithOverrides" } },
        );
      }
      if (this.userInfo.roles.find((r) => r.match(/^rep/))) {
        links.unshift(
           { text: "My Pay", to: { name: "pay" } },
           ...this.pendingPayLinks,
           // { text: "My Overrides", to: { name: "overridesListForUser" } },
        );
      }
      if (this.userInfo.roles.find((r) => r === "rep_intern")) {
        const removeIdx = links.findIndex(l => l.text === "My Pending Pay");
        if (removeIdx !== -1) {
          links.splice(removeIdx, 1);
        }
      }
      this.navLinks = links;
    },

    async getRepName() {
      const repUserId = this.$route.params.repUserId;
      if (!repUserId) {
        return;
      }
      this.repName = (await this.authedClient.get(`/user/${repUserId}/name`)).data;
    },

    async getFeatures() {
      const { data: features } = await this.authedClient.get("/nav/features");
      this.features = features;
    },

    spreadCrumbs() {
      let pending_pay_crumb_match = {};
      if (this.features?.pending_pay_caliber_types) {
        const pendingPayType = this.$route.params.pendingPayType;
        const pendingPayTypeMap = {
          solar: "My Reps Solar Pending Pay",
          calibersmart: "My Reps Caliber Smart Pending Pay",
        };
        const pendingPayTypedRouteNameMap = {
          solar: "repListPendingPayType",
          calibersmart: "repListPendingPayType",
        };
        pending_pay_crumb_match = {"rep-pending-pay-type": () => ({ text: pendingPayTypeMap[pendingPayType], to: { name: pendingPayTypedRouteNameMap[pendingPayType]}})};
      } else {
        pending_pay_crumb_match = {"rep-pending-pay": () => ({ text: "My Reps Pending Pay", to: { name: "repListPendingPay" }})};
      }

      const currentRoute = this.$router.currentRoute;
      const curRouteParams = currentRoute.params;
      const lastMatch = this.$router.currentRoute.matched.at(-1).path;

      const pathCrumbMap = {
        pay: () => ({ text: "My Pay", to: { name: "pay" } }),
        "pending-pay": () => ({ text: "My Pending Pay", to: { name: "pendingPay" } }),
        "rep-pay": () => ({ text: "My Reps Pay", to: { name: "repListPay" } }),
        // "rep-pending-pay": () => ({ text: "My Reps Pending Pay", to: { name: "repListPendingPay" }}),
        ":repUserId": () => ({ text: this.repName, to: { name: "repPay", props: { ...curRouteParams }}}),
        ":payDate": () => ({ text: curRouteParams.payDate, to: { name: "repLineitems", props: { ...curRouteParams }}}),
        ...pending_pay_crumb_match,
        search: () => ({
          text: `Search: "${this.searchStr}"`,
          to: {
            name: this.repName ? "repSearchResults" : "searchResults",
            props: { ...curRouteParams },
          },
        }),
      };
      const breadcrumbs = lastMatch
        .split("/")
        .filter((s) => Object.prototype.hasOwnProperty.call(pathCrumbMap, s))
        .map((pathSegment) => this.getContextualLink(pathCrumbMap[pathSegment]()));
      this.breadcrumbs = breadcrumbs.length > 1 ? breadcrumbs : 0;
    },

    updateNavRoutes() {
      this.setLinks();
      this.navLinks = this.navLinks.map(this.getContextualLink);
    },

    getContextualLink(link) {
      // no need to update the route if not in adminView
      if (!this.isAdminView) {
        return link;
      }

      return {
        ...link,
        to: {
          ...link.to,
          name: adminViewUserRouteMap[link.to.name],
        },
      };
    },
  },

  async beforeMount() {
    await Promise.all([this.getFeatures(), this.getRepName()]);
    this.updateNavRoutes();
    this.spreadCrumbs();
  },
};
</script>

<style scoped>
.user-nav {
  margin-bottom: 15px;
}
</style>