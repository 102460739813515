<template>
  <div>
    <b-input-group size="sm">
      <b-form-input
        id="filter-input"
        v-model="filter"
        type="search"
        placeholder="Filter"
      ></b-form-input>
      <b-input-group-append>
        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
      </b-input-group-append>
    </b-input-group>
    <b-table
      class="lineitem-table"
      striped
      hover
      stacked="sm"
      sort-by="includePayDate ? 'pay_date' : 'INSTALL DATE'"
      :sort-desc="true"
      :filter="filter"
      :filter-included-fields="filterFields"
      :fields="fields"
      :items="lineitemValues"
    >
      <template #cell(TOTAL)="row">
        <b-button
          variant="secondary"
          size="sm"
          @click="
            () => {
              hideDetails();
              openedTable(row);
            }
          "
        >
          $ {{ row.item.TOTAL }}
        </b-button>
      </template>
      <template #row-details="row">
        <b-card>
          <b-card-text class="dynamic-row">
            <b-row v-for="(row, index) in rows" :key="index" class="w-75 mx-auto mb-2">
              <b-col
                ><b>{{ row[0] }}</b></b-col
              >
              <b-col :class="{redtext: enableRedForNegative(row[1])}">{{ row[1] }}</b-col>
            </b-row>

            <b-row class="mb-2">
              <b-button
                variant="secondary"
                size="sm"
                @click="
                  () => {
                    row.toggleDetails();
                    rowsClear();
                  }
                "
              >
                Close
              </b-button>
            </b-row>
          </b-card-text>
        </b-card>
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: { lineitems: { type: Array, required: true },
           includePaydate: {type: Boolean, required: false, default: false }
  },
  computed: {
    lineitemValues() {
      return this.lineitems.map((lineitem) => {
        const cust_key = ["CUSTOMER NAME", "NOTE", "pay_category"].find((k) =>
          lineitem[k] ? true : false
        );
        return {
          ...lineitem,
          "CUSTOMER NAME": lineitem[cust_key],
          "INSTALL DATE": lineitem["INSTALL DATE"]
            ? moment(lineitem["INSTALL DATE"]).format("M/D/YYYY")
            : "N/A",
          TOTAL: lineitem.paid_total.toFixed(2),
        };
      });
    },
    fields() {
      const fields = [
        {
          key: "CUSTOMER NAME",
          label: "Customer",
        },
        {
          key: "product_type",
          label: "Product Type",
        },
        {
          key: "INSTALL DATE",
          label: "Install Date",
        },
        {
          key: "TOTAL",
          label: "Amount Earned",
        },
      ];

      if (this.includePaydate) {
        fields.push({
          key: "pay_date", "label": "Pay Date",
        });
      }
      return fields;
    }
  },
  data() {
    return {
      filter: "",
      filterFields: ["CUSTOMER NAME", "product_type", "INSTALL DATE", "NOTE"],
      currentItems: [],
      openedIndex: "",
      rowStatus: false,
      rows: [],
    };
  },

  methods: {
    openedTable(row) {
      if (row.index !== this.openedIndex) {
        this.rowsClear();
        this.lineitemFields(row.item);
        row.toggleDetails();
        this.openedIndex = row.index;
        return;
      }
      this.openedIndex = "";
      this.rowsClear();
    },
    hideDetails() {
      this.lineitemValues.forEach((item) => {
        this.$set(item, "_showDetails", false);
      });
    },
    rowsClear() {
      this.rows = [];
    },
    lineitemFields(lineitem) {
      this.filterFreedomSolar(lineitem);  // mutates the lineitem
      var potential_fields = [];
      switch (lineitem["product_type"]) {
        case "SOLAR":
          potential_fields = [
            { key: "COMPANY", format: "string" },
            { key: "REP", format: "string" },
            { key: "CUSTOMER NAME", format: "string" },
            { key: "INSTALL DATE", format: "date" },
            { key: "WO#", format: "number" },
            { key: "SOLAR TIER", format: "string" },
            { key: "PAYMENT TYPE", format: "string" },
            { key: "INSTALL PARTNER",  format: "string" },
            { key: "REDLINE", format: "money" },
            { key: "CONTRACT DATE", format: "date" },
            { key: "FINANCE", format: "string" },
            { key: "LEAD TYPE", format: "string" },
            { key: "STATE", format: "string" },
            { key: "PPW", format: "money" },
            { key: "SYSTEM SIZE (kw)", format: "string" },
            { key: "Pay Per (kw)", format: "money" },
            { key: "GROSS EARNED", format: "money" },
            { key: "LEAD/SETTER FEE PER KW", format: "money"},
            { key: "LEAD/SETTER FEE", format: "money"},
            { key: "NET PER KW", format: "money"},
            { key: "NET EARNED", format: "money"},
            { key: "SITE AUDIT FEE", format: "money" },
            { key: "LEAD FEE", format: "money", aliases: ["COMPANY LEAD FEE"]},
            { key: "UPFRONT PAY", format: "money" },
            { key: "INSTALL PAY", format: "money" },
            { key: "PTO PAY", format: "money" },
            { key: "ADJ", format: "money" },
            { key: "TOTAL", format: "money" },
            { key: "NOTE", format: "string" },
          ];
          break;
        case "BIG DOG SOLAR":
          potential_fields = [
            { key: "COMPANY", format: "string" },
            { key: "REP", format: "string" },
            { key: "CUSTOMER NAME", format: "string" },
            { key: "SOLAR TIER", format: "string" },
            { key: "INSTALL PARTNER",  format: "string" },
            { key: "REDLINE", format: "money" },
            { key: "CONTRACT DATE", format: "date" },
            { key: "FINANCE", format: "string" },
            { key: "LEAD TYPE", format: "string" },
            { key: "STATE", format: "string" },
            { key: "PPW", format: "money" },
            { key: "SYSTEM SIZE (kw)", format: "string" },
            { key: "Pay Per (kw)", format: "money" },
            { key: "GROSS EARNED", format: "money" },
            { key: "SITE AUDIT FEE", format: "money" },
            { key: "LEAD FEE", format: "money", aliases: ["COMPANY LEAD FEE"]},
            { key: "UPFRONT PAY", format: "money" },
            { key: "ADJ", format: "money" },
            { key: "TOTAL", format: "money" },
            { key: "NOTE", format: "string" },
          ];
          break;

        case "AVEYO SOLAR":
          potential_fields = [
            { key: "COMPANY", format: "string" },
            { key: "REP", format: "string" },
            { key: "CUSTOMER NAME", format: "string" },
            { key: "INSTALL DATE", format: "date" },
            { key: "WO#", format: "number" },
            { key: "SOLAR TIER", format: "string" },
            { key: "PAYMENT TYPE", format: "string" },
            { key: "INSTALL PARTNER",  format: "string" },
            { key: "REDLINE", format: "money" },
            { key: "CONTRACT DATE", format: "date" },
            { key: "FINANCE", format: "string" },
            { key: "LEAD TYPE", format: "string" },
            { key: "STATE", format: "string" },
            { key: "PPW", format: "money" },
            { key: "SYSTEM SIZE (kw)", format: "string" },
            { key: "Pay Per (kw)", format: "money" },
            { key: "GROSS EARNED", format: "money" },
            { key: "LEAD/SETTER FEE PER KW", format: "money"},
            { key: "LEAD/SETTER FEE", format: "money"},
            { key: "NET PER KW", format: "money"},
            { key: "NET EARNED", format: "money"},
            { key: "SITE AUDIT FEE", format: "money" },
            { key: "LEAD FEE", format: "money", aliases: ["COMPANY LEAD FEE"]},
            { key: "UPFRONT PAY", format: "money" },
            { key: "INSTALL PAY", format: "money" },
            { key: "PTO PAY", format: "money" },
            { key: "ADJ", format: "money" },
            { key: "TOTAL", format: "money" },
            { key: "NOTE", format: "string" },
          ];
          break;

        case "FREEDOM SOLAR":
          potential_fields = [
            { key: "COMPANY", format: "string" },
            { key: "REP", format: "string" },
            { key: "CUSTOMER NAME", format: "string" },
            { key: "INSTALL DATE", format: "date" },
            { key: "WO#", format: "number" },
            { key: "SOLAR TIER", format: "string" },
            { key: "PAYMENT TYPE", format: "string" },
            { key: "INSTALL PARTNER",  format: "string" },
            { key: "REDLINE", format: "money" },
            { key: "CONTRACT DATE", format: "date" },
            { key: "FINANCE", format: "string" },
            { key: "LEAD TYPE", format: "string" },
            { key: "STATE", format: "string" },
            { key: "PPW", format: "money" },
            { key: "SYSTEM SIZE (kw)", format: "string" },
            { key: "Pay Per (kw)", format: "money" },
            { key: "GROSS EARNED", format: "money" },
            { key: "LEAD/SETTER FEE PER KW", format: "money"},
            { key: "LEAD/SETTER FEE", format: "money"},
            { key: "NET PER KW", format: "money"},
            { key: "NET EARNED", format: "money"},
            { key: "SITE AUDIT FEE", format: "money" },
            { key: "UPFRONT PAY", format: "money" },
            { key: "INSTALL PAY", format: "money" },
            { key: "PTO PAY", format: "money" },
            { key: "ADJ", format: "money" },
            { key: "TOTAL", format: "money" },
            { key: "NOTE", format: "string" },
          ];
          break;

        case "LEGACY SOLAR":
          potential_fields = [
            { key: "COMPANY", format: "string" },
            { key: "REP", format: "string" },
            { key: "CUSTOMER NAME", format: "string" },
            { key: "INSTALL DATE", format: "date" },
            { key: "WO#", format: "number" },
            { key: "SOLAR TIER", format: "string" },
            { key: "PAYMENT TYPE", format: "string" },
            { key: "INSTALL PARTNER",  format: "string" },
            { key: "REDLINE", format: "money" },
            { key: "CONTRACT DATE", format: "date" },
            { key: "FINANCE", format: "string" },
            { key: "LEAD TYPE", format: "string" },
            { key: "STATE", format: "string" },
            { key: "PPW", format: "money" },
            { key: "SYSTEM SIZE (kw)", format: "string" },
            { key: "Pay Per (kw)", format: "money" },
            { key: "GROSS EARNED", format: "money" },
            { key: "LEAD/SETTER FEE PER KW", format: "money"},
            { key: "LEAD/SETTER FEE", format: "money"},
            { key: "NET PER KW", format: "money"},
            { key: "NET EARNED", format: "money"},
            { key: "SITE AUDIT FEE", format: "money" },
            { key: "LEAD FEE", format: "money", aliases: ["COMPANY LEAD FEE"]},
            { key: "UPFRONT PAY", format: "money" },
            { key: "INSTALL PAY", format: "money" },
            { key: "PTO PAY", format: "money" },
            { key: "ADJ", format: "money" },
            { key: "TOTAL", format: "money" },
            { key: "NOTE", format: "string" },
          ];
          break;

        case "COMPLETE SOLAR":
          potential_fields = [
            { key: "COMPANY", format: "string" },
            { key: "REP", format: "string" },
            { key: "CUSTOMER NAME", format: "string" },
            { key: "SOLAR TIER", format: "string" },
            { key: "INSTALL PARTNER",  format: "string" },
            { key: "REDLINE", format: "money" },
            { key: "CONTRACT DATE", format: "date" },
            { key: "FINANCE", format: "string" },
            { key: "LEAD TYPE", format: "string" },
            { key: "STATE", format: "string" },
            { key: "PPW", format: "money" },
            { key: "SYSTEM SIZE (kw)", format: "string" },
            { key: "Pay Per (kw)", format: "money" },
            { key: "GROSS EARNED", format: "money" },
            { key: "SITE AUDIT FEE", format: "money" },
            { key: "LEAD FEE", format: "money", aliases: ["COMPANY LEAD FEE"]},
            { key: "UPFRONT PAY", format: "money" },
            { key: "ADJ", format: "money" },
            { key: "TOTAL", format: "money" },
            { key: "NOTE", format: "string" },
          ];
          break;

        case "ARCADIA SOLAR":
          potential_fields = [
            { key: "COMPANY", format: "string" },
            { key: "REP", format: "string" },
            { key: "CUSTOMER NAME", format: "string"},
            { key: "PAY LEVEL", format: "string"},
            { key: "PROMOTION", format: "string"},
            { key: "STATE", format: "string"},
            { key: "PAYABLE KWH", format: "string"},
            { key: "PAYABLE PLAN", format: "string"},
            { key: "BASE", format: "money"},
            { key: "HLDBK", format: "money"},
            { key: "TOTAL", format: "money"},
            { key: "NOTE", format: "string"},
          ];
          break;
        case "SECURITY WARM TRANSFER":
          potential_fields = [
            { key: "COMPANY", format: "string" },
            { key: "REP", format: "string" },
            { key: "SECURITY TIER", format: "string" },
            { key: "PROMOTION", format: "string" },
            { key: "BASE", format: "money" },
            { key: "HLDBK", format: "money" },
            { key: "TOTAL", format: "money" },
            { key: "NOTE", format: "string" },
          ];
          break;
        case "UPFRONT PAY":
          potential_fields = [
            { key: "COMPANY", format: "string" },
            { key: "REP", format: "string" },
            { key: "CUSTOMER NAME", format: "string" },
            { key: "TOTAL", format: "money" },
            { key: "NOTE", format: "string" },
          ];
          break;
        case "SECURITY E2E":
          potential_fields = [
            { key: "COMPANY", format: "string" },
            { key: "REP", format: "string" },
            { key: "CUSTOMER NAME", format: "string" },
            { key: "SECURITY TIER", format: "string" },
            { key: "POINTS", format: "decimal" },
            { key: "POINTS USED", format: "decimal" },
            { key: "MMR", format: "money" },
            { key: "MMR CHARGED", format: "money" },
            { key: "PROMOTION", format: "string" },
            { key: "BASE", format: "money" },
            { key: "ACT. FEE", format: "money" },
            { key: "OUTDOOR INSTALL FEE", format: "money" },
            { key: "MMR ADJ", format: "money" },
            { key: "POINTS ADJ", format: "money" },
            { key: "HLDBK", format: "money" },
            { key: "TOTAL", format: "money" },
            { key: "NOTE", format: "string" },
          ];
          break;
        case "FLEX 24 MONTH":
          potential_fields = [
            { key: "COMPANY", format: "string" },
            { key: "REP", format: "string" },
            { key: "PAY LEVEL", format: "string" },
            { key: "PROMOTION", format: "string" },
            { key: "TOTAL", format: "money" },
            { key: "NOTE", format: "string" },
          ];
          break;
        case "DISH ACTIVATION FEE":
          potential_fields = [
            { key: "COMPANY", format: "string" },
            { key: "REP", format: "string" },
            { key: "PAY LEVEL", format: "string" },
            { key: "TYPE", format: "string" },
            { key: "TOTAL", format: "money" },
            { key: "NOTE", format: "string" },
          ];
          break;
        case "INTERNET":
          potential_fields = [
            { key: "COMPANY", format: "string" },
            { key: "REP", format: "string" },
            { key: "PAY LEVEL", format: "string" },
            { key: "PROMOTION", format: "string" },
            { key: "BASE", format: "money" },
            { key: "HLDBK", format: "money" },
            { key: "TOTAL", format: "money" },
            { key: "NOTE", format: "string" },
          ];
          break;
        case "T-MOBILE ACTIVATION FEE":
          potential_fields = [
            { key: "COMPANY", format: "string" },
            { key: "REP", format: "string" },
            { key: "PAY LEVEL", format: "string" },
            { key: "TYPE", format: "string" },
            { key: "TOTAL", format: "money" },
            { key: "NOTE", format: "string" },
          ];
          break;
        case "GEN ADJ":
          potential_fields = [
            { key: "COMPANY", format: "string" },
            { key: "REP", format: "string" },
            { key: "TOTAL", format: "money" },
            { key: "NOTE", format: "string" },
          ];
          break;
        case "DISH":
          potential_fields = [
            { key: "COMPANY", format: "string" },
            { key: "REP", format: "string" },
            { key: "PAY LEVEL", format: "string" },
            { key: "QT", format: "string" },
            { key: "BASE", format: "money" },
            { key: "HOPPER", format: "money" },
            { key: "T200", format: "money" },
            { key: "E2E", format: "money" },
            { key: "FLEX PACK", format: "money" },
            { key: "REFER A FRIEND", format: "money" },
            { key: "NON-DVR", format: "money" },
            { key: "FORMERS", format: "money" },
            { key: "INTRO CREDIT", format: "money" },
            { key: "HLDBK", format: "money" },
            { key: "TOTAL", format: "money" },
            { key: "NOTE", format: "string" },
          ];
          break;
        case "T-MOBILE":
          potential_fields = [
            { key: "COMPANY", format: "string" },
            { key: "REP", format: "string" },
            { key: "PAY LEVEL", format: "string" },
            { key: "CREDIT", format: "string" },
            { key: "DEVICE", format: "string" },
            { key: "MRC", format: "money" },
            { key: "MULT", format: "decimal" },
            { key: "BASE", format: "money" },
            { key: "HLDBK", format: "money" },
            { key: "TOTAL", format: "money" },
            { key: "NOTE", format: "string" },
          ];
          break;
        default:
          potential_fields = [
            { key: "COMPANY", format: "string" },
            { key: "REP", format: "string" },
            { key: "CUSTOMER NAME", format: "string" },
            { key: "TOTAL", format: "money" },
            { key: "NOTE", format: "string" },
          ];
      }

      potential_fields.forEach((column) => {
        let field_name = column["key"];
        let field_alias = [column["key"], ...(column?.aliases || [])].find(f => Object.keys(lineitem).includes(f));

        if (field_alias) {
          let field_value = lineitem[field_alias];
          let row = [];
          if (column["format"] == "money") {
            if (Number.isNaN(Number.parseFloat(field_value))) {
              row = [field_name, field_value];
            } else {
              row = [field_name, `$ ${parseFloat(field_value).toFixed(2)}`];
            }
          } else if (column["format"] == "decimal") {
            if (field_name == "POINTS" || field_name == "POINTS USED") {
              if (Number.isNaN(Number.parseFloat(field_value))) {
                row = [field_name, field_value];
              } else {
                row = [field_name, `${parseFloat(field_value).toFixed(1)}`];
              }
            } else {
              if (Number.isNaN(Number.parseFloat(field_value))) {
                row = [field_name, field_value];
              } else {
                row = [field_name, `${parseFloat(field_value).toFixed(2)}`];
              }
            }
          } else if (column["format"] == "date") {
            row = [field_name, moment(field_value).format("M/D/YYYY")];
          } else {
            row = [field_name, field_value];
          }
          this.rows.push(row);
        }
      });
    },
    filterFreedomSolar(lineitem) {
      if (lineitem["INSTALL PAY"] === 0) {
        // delete "INSTALL PAY" if its value is zero
        delete lineitem["INSTALL PAY"];
      }

      const common_deletes = () => {
          // FIXME, we might not need these since '0' items are filtered in upload.py
          // delete any of these that are zero
          if (!lineitem["LEAD/SETTER FEE PER KW"]) {
            delete lineitem["LEAD/SETTER FEE PER KW"];
          }
          if (!lineitem["LEAD/SETTER FEE"]) {
            delete lineitem["LEAD/SETTER FEE"];
          }
          if (!lineitem["NET PER KW"]) {
            delete lineitem["NET PER KW"];
          }
      };

      // NOTE that this filtering occurs BEFORE aliases are resolved
      if (["FREEDOM SOLAR", "SOLAR", "AVEYO SOLAR", "LEGACY SOLAR"].find(t => t == lineitem["product_type"]) !== undefined) {
        if (lineitem["GROSS EARNED"] == lineitem["NET EARNED"]) {
          common_deletes();

          // delete NET EARNED because it's == GROSS EARNED -- redundant
          if (lineitem["NET EARNED"]) {
            delete lineitem["NET EARNED"];
          }
        }

        if (lineitem["Pay Per (kw)"] == lineitem["NET PER KW"]) {
          common_deletes();
          // If "Pay Per (kw)" and "NET PER KW" are the same then "NET PER KW" should not display...
          if (lineitem["NET PER KW"]) {
            delete lineitem["NET PER KW"];
          }
        }

        if (lineitem["LEAD/SETTER FEE PER KW"] === 0 && lineitem["LEAD/SETTER FEE"] === 0) {
          delete lineitem["LEAD/SETTER FEE PER KW"];
          delete lineitem["LEAD/SETTER FEE"];
          delete lineitem["NET PER KW"];
          delete lineitem["NET EARNED"];
        }
      }
    },
    enableRedForNegative(value) {
      if (/^\$ /.exec(value) === null) {
        // not money
        return false;
      }

      const numeric_value = Number.parseFloat(value.replace(/^\$ /, ""));
      if (Number.isNaN(numeric_value)) {
        return false;
      }
      if (numeric_value < 0) {
        return true;
      }
    },
  },
};
</script>

<style>
.lineitem-table td {
  word-wrap: break-word;
}

.redtext {
  color: red;
}

@media screen and (min-width: 575.98px) {
  .lineitem-table td {
    word-wrap: inherit;
  }
}
</style>
