<template>
  <b-container fluid>
    <b-card no-body class="mb-1">
      <b-card-header v-b-toggle.create-user class="p-0">
        <span class="when-closed">
          <b-button block variant="outline-secondary"
            >Create User
            <b-icon font-scale=".65" icon="triangle-fill" rotate="0" class="ml-1 mb-1"></b-icon>
          </b-button>
        </span>
        <span class="when-open">
          <b-button block
            >Create User
            <b-icon font-scale=".65" icon="triangle-fill" rotate="60" class="ml-1 mb-1"></b-icon>
          </b-button>
        </span>
      </b-card-header>
      <b-collapse id="create-user">
        <b-card-body>
          <EditUser></EditUser>
        </b-card-body>
      </b-collapse>
    </b-card>
    <UserSupervisors @needUserListRefresh="toggleUserRefreshTrigger"></UserSupervisors>
    <UserList
      :triggerListRefresh="triggerListRefresh"
      @doneLoading="toggleUserRefreshTrigger"
    ></UserList>
  </b-container>
</template>

<script>
import EditUser from "./EditUser.vue";
import UserSupervisors from "./components/UserSupervisors.vue";
import UserList from "./Users/UserList.vue";

export default {
  components: { EditUser, UserList, UserSupervisors },
  data() {
    return { triggerListRefresh: true };
  },
  methods: {
    toggleUserRefreshTrigger() {
      this.triggerListRefresh = !this.triggerListRefresh;
    },
  },
};
</script>

<style></style>
