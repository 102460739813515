<template>
  <div>
    <b-spinner v-if="processing" variant="primary"></b-spinner>
    <b-form v-else>
      <b-form-group
        label="Overrides File: "
        label-for="file-overrides"
      >
        <b-form-file
          id="file-overrides"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          v-model="overridesFile"
          required
        >
        </b-form-file>
      </b-form-group>

      <b-form-group description="select an override type">
        <b-form-radio-group
          v-model="overridesType"
          :options="overridesTypes"
          name="overridesType"
        >
        </b-form-radio-group>
      </b-form-group>

      <b-form-group description="select a product type">
        <b-form-select
          v-model="productType"
          :options="productTypes"
        ></b-form-select>
      </b-form-group>

      <b-row>
        <b-col lg="1" sm="2">
          <b-form-group description="select month">
            <b-form-select v-model="overridesMonth" :options="monthOptions"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="2" sm="2">
          <b-form-group description="select year">
            <b-form-select v-model="overridesYear" :options="yearOptions"></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-button
        variant="primary"
        @click="uploadOverrides"
        :disabled="!(overridesFile && overridesType && overrideMonth && productType)"
      >
        <b-icon icon="cloud-upload-fill"></b-icon> Upload
      </b-button>
    </b-form>
    <div v-if="batchList.length < 1">No uploads yet</div>
    <b-table v-else class="mt-4" stacked="md" striped hover :fields="batchListFields" :items="batchList">
      <template #cell(actions)="row">
        <b-button variant="secondary" @click="deleteOverridesBatch(row)">
          <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import requestCommon from "../../../components/requestCommon.vue";
import moment from "moment";

export default {
  mixins: [requestCommon],

  computed: {
    ...mapGetters({adminClient: "api/adminClient"}),
    yearOptions() {
      const currentYear = moment().year();
      const options = Array(5).fill(currentYear);
      let offset = -2;
      return options.map(i => i + offset++);
    },
  },

  data() {
    return {
      processing: false,
      overridesMonth: moment().month(),
      overridesYear: moment().year(),
      overridesFile: null,
      overridesTypeState: false,
      overridesType: null,
      overridesTypes: ["manager", "regional", "premium", "executive"],
      productTypes: [],
      productType: null,
      batchList: [{upload_date: "05-16-2022 11:25:43 pm", upload_file: "myfile.xlsx", batch_id: 1, override_type: "manager", month: "02-2022"}],
      monthOptions: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, ],
      batchListFields: [
        { key: "upload_date", label: "Upload Date" },
        { key: "upload_file", label: "File Name" },
        { key: "override_type", label: "Type"},
        { key: "month", label: "Pay Month"},
        { key: "actions", label: "Delete" },
        { key: "batch_id", thClass: "d-none", tdClass: "d-none" },
      ],
    };
  },

  methods: {
    async uploadOverrides() {
      const formData = new FormData();
      formData.append("file", this.overridesFile);
      formData.append("overrides_type", this.overridesType);
      this.processing = true;

      try {
        await this.adminClient.post("/overrides/batches", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        this.toastActionStatus({
          title: "Upload",
          message: "success",
          variant: "success",
          delay: 3000,
        });
      } catch (error) {
        // TODO use upload version of this
        this.handleRequestError(error);
      }

      this.overridesFile = null;
      await this.fetchOverridesBatches();
      this.processing = false;
    },

    async fetchOverridesBatches() {
      console.warn("fetchOverridesBatches not yet implemented");
    },

    async deleteOverridesBatch(row) {
      const {
        item: { upload_file: uploadFile, batch_id: batchId },
        index,
      } = row;

      if (confirm(`are you sure you want to delete ${uploadFile}`)) {
        try {
          await this.adminClient.delete(`/overrides/batches/${batchId}`);
          this.batchList.splice(index, 1);
          this.toastActionStatus({
            title: "Delete",
            message: "success",
            variant: "success",
            delay: 3000,
          });
        } catch (error) {
          this.handleRequestError(error);
        }
      }
    },
    async fetchProductTypes() {
      try {
        const { data: {products}} = (await this.adminClient.get('/lineitem-products'));
        console.log(products);
        this.productTypes = products.map(p => ({
          value: p.product_name,
          text: p.product_name,
        }));
        this.productTypes.unshift({value: null, text: "select a product", disabled: true})
      } catch (error) {
        this.handleRequestError(error);
      }
    },
  },

  async mounted() {
    await Promise.all([
      this.fetchOverridesBatches(),
      this.fetchProductTypes(),
    ])
    this.processing = false;
  }
};
</script>