<script>
export default {
  methods: {
    handleRequestError(error, httpStatusHandlers) {
      const respStatus = error.response?.status;

      if (!respStatus) {
        console.error(error);
      }

      // define generic response handlers
      const errorMessages = {
        [respStatus]: "request failed for an unknown reason",
        400: "there was something wrong with your request",
        401: "it appears your session is expired. log in again",
        403: "you do not have access",
        404: "requested action was not found",
        500: "unexpected server error",
        ...httpStatusHandlers, // allow for overriding
      };

      const errorMessage = errorMessages[respStatus];

      this.toastActionStatus({
        title: "Request failed",
        message: `Error ${errorMessage}: ${JSON.stringify(error?.response?.data)}`,
        variant: "danger",
      });
    },

    toastActionStatus({ title, message, variant, delay }) {
      let delay_args = { noAutoHide: true };
      if (delay) {
        delay_args = {autoHideDelay: delay};
      }

      this.$root.$bvToast.toast(message, {
        title: title || "",
        variant: variant || "primary",
        solid: true,
        ...delay_args,
      });
    },
  },
};
</script>
