<template>
  <div>
    <b-spinner v-if="loading" variant="primary"></b-spinner>
    <div v-else-if="totals.length">
      <b-container class="mb-2" fluid>
        <b-row>
          <b-col sm="6">
            <LineitemSearch></LineitemSearch>
          </b-col>
          <b-col sm="6">
            <PDFDownload></PDFDownload>
          </b-col>
        </b-row>
      </b-container>
      <Payments :payments="payments" :categoryTotals="totals"></Payments>
    </div>
    <p v-else>No payment items to display</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Payments from "./Payments";
import LineitemSearch from "../components/LineitemSearch";
import PDFDownload from "../components/PDFDownload";

export default {
  components: { Payments, LineitemSearch, PDFDownload },

  data() {
    return { payments: [], totals: [], loading: true };
  },
  computed: { ...mapGetters({ authedClient: "api/authedClient" }) },

  methods: {
    async fetchPayments() {
      this.payments = (await this.authedClient.get("/pay-history")).data;
    },
    async fetchCategoryTotals() {
      this.totals = (await this.authedClient.get("/pay-history/categories")).data;
    },
  },

  async mounted() {
    await Promise.all([this.fetchPayments(), this.fetchCategoryTotals()]);
    this.loading = false;
  },
};
</script>
