<template>
  <div>
    <div v-if="ready">
      <b-alert variant="primary" show class="view-alert"
        ><p class="alert-text">Viewing as: {{ targetUser.name }}</p>
        <router-link :to="{ name: 'adminUsers' }"
          ><b-button variant="primary">Back to user list</b-button></router-link
        ></b-alert
      >
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: { userId: { type: String, required: true } },
  data() {
    return { ready: false };
  },

  computed: {
    ...mapGetters({
      targetUser: "adminView/targetUser",
      adminClient: "api/adminClient",
    }),
  },

  methods: { ...mapActions({ adminViewEnable: "adminView/enable" }) },

  async created() {
    // create the user session
    const {
      data: { user: targetUser, token: userToken },
    } = await this.adminClient.post(`user/${this.userId}/session`);

    await this.adminViewEnable({ targetUser, userToken });
    this.ready = true;
  },
};
</script>

<style scoped>
.view-alert {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
.alert-text {
  display: inline;
  margin-bottom: 0rem;
}
</style>
