/** Defines the routes available within our application
 * See: https://router.vuejs.org/guide/#html
 */
import AdminSiteConfiguration from "./views/admin/SiteConfiguration"
import AdminEditUser from "./views/admin/EditUser";
import AdminProducts from "./views/admin/Products";
import AdminUploads from "./views/admin/Uploads";
import AdminUploadsOverrides from "./views/admin/Uploads/Overrides";
import AdminUploadsPayHistory from "./views/admin/Uploads/PayHistory";
import AdminUploadsPendingPay from "./views/admin/Uploads/PendingPay";
import AdminUsers from "./views/admin/Users";
import AdminViewAsUser from "./views/admin/ViewAsUser";
import AdminWrapper from "./views/admin/AdminWrapper";
import ErrorGeneric from "./views/general/ErrorGeneric";
import Index from "./views/general/Index";
import LineitemSearchResults from "./views/LineitemSearchResults";
import LineitemsForRep from "./views/LineitemsForRep";
import LineitemsForUser from "./views/LineitemsForUser";
import LineitemsPendingForRep from "./views/LineitemsPendingForRep";
import LineitemsPendingForUser from "./views/LineitemsPendingForUser";
import OverridesListForRep from "./views/OverridesListForRep";
import OverridesListForUser from "./views/OverridesListForUser";
import OverridesForUser from "./views/OverridesForUser";
import OverridesForRep from "./views/OverridesForRep";
import PaymentsForRep from "./views/PaymentsForRep";
import PaymentsForUser from "./views/PaymentsForUser";
import RepsWithOverrides from "./views/supervisor/RepsWithOverrides";
import RepsWithLineitemsPending from "./views/supervisor/RepsWithLineitemsPending";
import RepsWithPayments from "./views/supervisor/RepsWithPayments";
import User from "./views/User";
import UserLogin from "./views/general/UserLogin";

export const adminViewUserRouteMap = {
  lineitems: "adminViewLineitems",
  overridesListForRep: "adminViewOverridesListForRep",
  overridesListForUser: "adminViewOverridesListForUser",
  overridesForUser: "adminViewOverridesForUser",
  overridesForRep: "adminViewOverridesForRep",
  pay: "adminViewPay",
  pendingPay: "adminViewPendingPay",
  pendingPayType: "adminViewPendingPayType",
  repLineitems: "adminViewLineitemsForRep",
  repsWithOverrides: "adminViewRepsWithOverrides",
  repListPay: "adminViewRepsWithPayments",
  repListPendingPay: "adminViewRepsPendingPay",
  repListPendingPayType: "adminViewRepsPendingPayType",
  repPay: "adminViewPaymentsForRep",
  repPendingPay: "adminViewLineitemsPendingForRep",
  repPendingPayType: "adminViewLineitemsPendingTypeForRep",
  repSearchResults: "adminViewRepSearchResults",
  searchResults: "adminViewSearchResults",
};

const adminRoutes = [
  {
    path: "/admin",
    name: "adminHome",
    component: AdminWrapper,
    children: [
      {
        path: "configuration",
        name: "configuration",
        component: AdminSiteConfiguration,
      },
      {
        path: "uploads",
        name: "adminUploads",
        component: AdminUploads,
        children: [
          {
            path: "pending-pay",
            name: "uploadsPendingPay",
            component: AdminUploadsPendingPay,
          },
          {
            path: "pending-pay/:pendingPayType",
            name: "uploadsPendingPayType",
            component: AdminUploadsPendingPay,
            props: true,
          },
          {
            path: "pay-history",
            name: "uploadsPayHistory",
            component: AdminUploadsPayHistory,
          },
          {
            path: "overrides",
            name: "uploadsOverrides",
            component: AdminUploadsOverrides,
          },
        ],
      },
      {
        path: "users/:userId",
        name: "adminEditUser",
        component: AdminEditUser,
        props: true,
      },
      {
        path: "users",
        name: "adminUsers",
        component: AdminUsers,
      },
      {
        path: "products",
        name: "adminProducts",
        component: AdminProducts,
      },
    ],
  },
  {
    path: "/admin/view-user/:userId",
    component: AdminViewAsUser,
    props: true,
    children: [
      {
        path: "user",
        component: User,
        name: "adminViewUser",
        children: [
          // admin view user pages
          {
            path: "overrides",
            name: adminViewUserRouteMap.overridesListForUser,
            component: OverridesListForUser,
          },
          {
            path: "overrides/:overridesType/:month/:productType",
            name: adminViewUserRouteMap.overridesForUser,
            component: OverridesListForUser,
            props: true,
          },
          {
            path: "pay/search",
            name: adminViewUserRouteMap.searchResults,
            component: LineitemSearchResults,
          },
          {
            path: "pay/:payDate",
            name: adminViewUserRouteMap.lineitems,
            component: LineitemsForUser,
            props: true,
          },
          {
            path: "pay",
            name: adminViewUserRouteMap.pay,
            component: PaymentsForUser,
          },
          {
            path: "pending-pay",
            name: adminViewUserRouteMap.pendingPay,
            component: LineitemsPendingForUser,
          },

          {
            path: "pending-pay/:pendingPayType",
            name: adminViewUserRouteMap.pendingPayType,
            component: LineitemsPendingForUser,
            props: true,
          },

          // admin view supervisor pages
          {
            path: "rep-overrides",
            name: adminViewUserRouteMap.repsWithOverrides,
            component: RepsWithOverrides,
          },
          {
            path: "rep-overrides/:repUserId",
            name: adminViewUserRouteMap.overridesListForRep,
            component: OverridesListForRep,
            props: true,
          },
          {
            path: "rep-overrides/:repUserId/:overridesType/:month/:productType",
            name: adminViewUserRouteMap.overridesForRep,
            component: OverridesForRep,
            props: true,
          },
          {
            path: "rep-pay/:repUserId/search",
            name: adminViewUserRouteMap.repSearchResults,
            component: LineitemSearchResults,
            props: true,
          },
          {
            path: "rep-pay/:repUserId/:payDate",
            name: adminViewUserRouteMap.repLineitems,
            component: LineitemsForRep,
            props: true,
          },
          {
            path: "rep-pay",
            name: adminViewUserRouteMap.repListPay,
            component: RepsWithPayments,
          },
          {
            path: "rep-pay/:repUserId",
            name: adminViewUserRouteMap.repPay,
            component: PaymentsForRep,
            props: true,
          },
          {
            path: "rep-pending-pay",
            name: adminViewUserRouteMap.repListPendingPay,
            component: RepsWithLineitemsPending,
          },
          {
            path: "rep-pending-pay-type/:pendingPayType",
            name: adminViewUserRouteMap.repListPendingPayType,
            component: RepsWithLineitemsPending,
            props: true,
          },
          {
            path: "rep-pending-pay/:repUserId",
            name: adminViewUserRouteMap.repPendingPay,
            component: LineitemsPendingForRep,
            props: true,
          },
          {
            path: "rep-pending-pay-type/:pendingPayType/:repUserId",
            name: adminViewUserRouteMap.repPendingPayType,
            component: LineitemsPendingForRep,
            props: true,
          },
        ],
      },
    ],
  },
];

const userRoutes = [
  {
    path: "/user",
    component: User,
    children: [
      {
        path: "overrides",
        name: "overridesListForUser",
        component: OverridesListForUser,
      },
      {
        path: "overrides/:overridesType/:month/:productType",
        name: "overridesForUser",
        component: OverridesForUser,
        props: true,
      },
      {
        path: "pay/search",
        name: "searchResults",
        component: LineitemSearchResults,
      },
      {
        path: "/pay",
        name: "pay",
        component: PaymentsForUser,
      },
      {
        path: "/pay/:payDate",
        name: "lineitems",
        component: LineitemsForUser,
        props: true,
      },
      {
        path: "/pending-pay",
        name: "pendingPay",
        component: LineitemsPendingForUser,
      },
    ],
  },
];

const supervisorUserRoutes = [
  {
    path: "/user",
    component: User,
    children: [
      {
        path: "/rep-pay/:repUserId/search",
        name: "repSearchResults",
        component: LineitemSearchResults,
        props: true,
      },
      {
        // list of subordinates
        path: "/rep-pay",
        name: "repListPay",
        component: RepsWithPayments,
      },
      {
        // list of one subordinate's pay dates
        path: "/rep-pay/:repUserId",
        name: "repPay",
        component: PaymentsForRep,
        props: true,
      },
      {
        // list of one subordinate's lineitems
        path: "/rep-pay/:repUserId/:payDate",
        name: "repLineitems",
        component: LineitemsForRep,
        props: true,
      },
      {
        // list of subordinates that have lineitems pending
        path: "/rep-pending-pay",
        name: "repListPendingPay",
        component: RepsWithLineitemsPending,
      },
      {
        // list of one subordinate's lineitems pending
        path: "/rep-pending-pay/:repUserId",
        name: "repPendingPay",
        component: LineitemsPendingForRep,
        props: true,
      },
      {
        path: "/rep-overrides",
        name: "repsWithOverrides",
        component: RepsWithOverrides,
      },
      {
        path: "/rep-overrides/:repUserId",
        name: "overridesListForRep",
        component: OverridesListForRep,
        props: true,
      },
      {
        path: "/rep-overrides/:repUserId/:overridesType/:month/:productType",
        name: "overridesForRep",
        component: OverridesForRep,
        props: true,
      },

    ],
  },
];

const miscRoutes = [
  {
    name: "index",
    path: "/",
    component: Index,
  },
  {
    name: "genericError",
    path: "/error",
    component: ErrorGeneric,
    props: true,
  },
  {
    name: "sso",
    path: "/user-login",
    component: UserLogin,
  },
];

export default [...adminRoutes, ...userRoutes, ...supervisorUserRoutes, ...miscRoutes];