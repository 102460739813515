<template>
  <div>
    <b-card no-body>
      <b-card-header v-b-toggle.pay-by-category class="p-0">
        <span class="when-closed">
          <b-button block variant="outline-secondary"
            >Combined pay by category
            <b-icon font-scale=".65" icon="triangle-fill" rotate="0" class="ml-1 mb-1"></b-icon>
          </b-button>
        </span>
        <span class="when-open">
          <b-button block
            >Combined pay by category
            <b-icon font-scale=".65" icon="triangle-fill" rotate="60" class="ml-1 mb-1"></b-icon>
          </b-button>
        </span>
      </b-card-header>
      <b-collapse id="pay-by-category">
        <b-card-body>
          <b-row>
            <b-col>
              <b-list-group class="categories">
                <b-list-group-item
                  v-for="i in evens"
                  :key="i['pay_category']"
                  class="d-flex justify-content-between align-items-center"
                  id="pay-category-text"
                >
                  {{ i["pay_category"] }}
                  <b-badge
                    :variant="i['paid_total'] > 0 ? 'success' : 'danger'"
                    pill
                    id="pill-text"
                  >
                    $ {{ i["paid_total"].toFixed(2) }}
                  </b-badge>
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col>
              <b-list-group class="categories">
                <b-list-group-item
                  v-for="i in odds"
                  :key="i['pay_category']"
                  class="d-flex justify-content-between align-items-center"
                  id="pay-category-text"
                >
                  {{ i["pay_category"] }}
                  <b-badge
                    :variant="i['paid_total'] > 0 ? 'success' : 'danger'"
                    pill
                    id="pill-text"
                  >
                    $ {{ i["paid_total"].toFixed(2) }}
                  </b-badge>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
export default {
  props: { totals: { type: Array, required: true } },
  computed: {
    evens() {
      return this.totals.filter((element, index) => {
        return index % 2 === 0;
      });
    },
    odds() {
      return this.totals.filter((element) => {
        return !this.evens.includes(element);
      });
    },
  },
};
</script>

<style>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

#pay-category-text {
  font-weight: bold;
  font-size: 1rem;
}

#pill-text {
  font-size: 0.875rem;
}

@media screen and (min-width: 575.98px) {
  .categories {
    font-size: 1.5rem;
  }
}
</style>
