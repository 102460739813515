<template>
  <div></div>
</template>

<script>
export default {
  /* When users find themselves trying to get to this page, they should be directed to the
   * homepage that best matches their role. This method orchestrates that behavior.
   */
  beforeRouteEnter(to, from, next) {
    next((vueInstance) => {
      const userRoles = vueInstance.$store.getters["auth/userRoles"];

      // send admins to the "admin homepage"
      const isAdmin = !!userRoles.find((role) => role === "admin");
      if (isAdmin) {
        vueInstance.$router.replace({ name: "uploadsPayHistory" });
        return;
      }

      // send supervisors to the "rep homepage" (for now)
      // send reps to the "rep homepage"
      const isRep = !!userRoles.find((role) => role === "rep" || role === "supervisor");
      if (isRep) {
        vueInstance.$router.replace({ name: "pay" });
        return;
      }

      // they have nowhere to go, send them to the generic error page
      vueInstance.$router.push({ name: "genericError" });
    });
  },
};
</script>