<template>
  <div>
    <b-spinner v-if="loading" variant="primary"></b-spinner>
    <div v-else>
      <LineitemSearch :repUserId="repUserId" class="mb-2"></LineitemSearch>
      <Payments :payments="payments" :categoryTotals="totals"></Payments>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Payments from "./Payments";
import LineitemSearch from "../components/LineitemSearch";

export default {
  components: { Payments, LineitemSearch },

  props: { repUserId: { type: String, required: true } },
  data() {
    return { payments: [], totals: [], loading: true };
  },
  computed: { ...mapGetters({ authedClient: "api/authedClient" }) },

  methods: {
    async fetchPayments() {
      // TODO this should be a seprate endpoint from the one a single user would use
      const params = { user_id: this.repUserId };
      this.payments = (await this.authedClient.get("/pay-history", { params })).data;
    },
    async fetchCategoryTotals() {
      // TODO this should be a seprate endpoint from the one a single user would use
      const params = { user_id: this.repUserId };
      this.totals = (await this.authedClient.get("/pay-history/categories", { params })).data;
    },
  },

  async mounted() {
    await Promise.all([this.fetchPayments(), this.fetchCategoryTotals()]);
    this.loading = false;
  },
};
</script>
