<template>
  <div>
    <b-form-group label="User Info" label-cols-lg="auto">
      <b-form-group description="User id">
        <b-form-input type="text" v-model="userInfo.user_id" disabled></b-form-input>
      </b-form-group>

      <b-form-group description="User name">
        <b-form-input type="text" v-model="userInfo.name"></b-form-input>
      </b-form-group>

      <b-form-group description="User email">
        <b-form-input type="email" v-model="userInfo.email"></b-form-input>
      </b-form-group>

      <b-form-group label="Assigned Roles">
        <b-form-checkbox-group
          v-model="userInfo.roles"
          :options="availableRoles"
          stacked
          switches
        ></b-form-checkbox-group>
      </b-form-group>

      <b-form-group label="Status">
        <b-form-radio v-model="userInfo.active" :value="true"> Enabled </b-form-radio>
        <b-form-radio v-model="userInfo.active" :value="false"> Disabled </b-form-radio>
      </b-form-group>
    </b-form-group>
  </div>
</template>

<script>
import requestCommon from "../../../components/requestCommon";
import { mapGetters } from "vuex";

export default {
  mixins: [requestCommon],
  computed: {
    ...mapGetters({
      adminClient: "api/adminClient",
    }),
    userRoles() {
      return this.userInfo.roles;
    },
  },
  props: {
    userId: { type: String, default: "" },
    triggerUserSave: { type: Boolean, default: false },
  },
  watch: {
    async triggerUserSave(trigger) {
      if (trigger) {
        if (this.userId) {
          await this.updateUser();
        } else {
          await this.createUser();
        }
        this.$emit("doneLoading");
      }
    },
    async userRoles(newRoles) {
      this.$emit("enableRepRole", newRoles.find(r => r.match(/^rep/)));
    },
  },
  data() {
    return {
      userInfo: {
        active: true,
        email: "",
        name: "",
        roles: [],
        user_id: "",
      },
      availableRoles: [],
    };
  },

  methods: {
    async fetchUserInfo() {
      this.userInfo = (await this.adminClient.get(`/user/${this.userId}`)).data;
    },

    async fetchAvailableRoles() {
      const {
        data: { roles },
      } = await this.adminClient.get("/roles");
      this.availableRoles = roles.map((r) => ({
        value: r.name,
        text: `${r.name} - ${r.description}`,
      }));
    },

    async updateUser() {
      try {
        await this.adminClient.patch(`/user/${this.userId}`, this.userInfo);
        this.toastActionStatus({
          title: "User update",
          message: "success",
          variant: "success",
          delay: 3000,
        });
      } catch (error) {
        this.handleRequestError(error);
      }
    },

    async createUser() {
      try {
        const {
          data: { user_id: userId },
        } = await this.adminClient.post("/user", this.userInfo);
        this.$emit("userCreated", userId);
        this.toastActionStatus({
          title: "User create",
          message: "success",
          variant: "success",
          delay: 3000,
        });
      } catch (error) {
        this.handleRequestError(error);
      }
    },
  },

  async mounted() {
    const promises = [this.fetchAvailableRoles()];
    if (this.userId) {
      promises.push(this.fetchUserInfo());
    }
    await Promise.all(promises);
    this.$emit("doneLoading");
  },
};
</script>
