export default {
  namespaced: true,
  state: {
    lineitemSearchStr: "",
  },

  mutations: {
    setLineitemSearchStr(state, newSearch) {
      state.lineitemSearchStr = newSearch;
    },
  },

  getters: {
    lineitemSearchStr(state) {
      return state.lineitemSearchStr;
    },
  },
};
