<template>
  <div>
    <b-alert v-if="pendingTotal" show variant="info">
      TOTAL PENDING FOR {{ displayPendingPayDate }} PAY DATE: ${{ displayPendingTotal }}
    </b-alert>
    <Lineitems :lineitems="pendingLineitems"></Lineitems>
  </div>
</template>

<script>
import Lineitems from "./Lineitems";
import moment from "moment";

export default {
  components: { Lineitems },

  props: {
    pendingTotal: { type: Number, required: true },
    pendingLineitems: { type: Array, required: true },
    pendingPayDate: { type: String, required: true },
  },

  computed: {
    displayPendingTotal() {
      return this.pendingTotal ? `${parseFloat(this.pendingTotal).toFixed(2)}` : "";
    },
    displayPendingPayDate() {
      return this.pendingPayDate ? moment(this.pendingPayDate).format("M/D/YYYY") : "";
    },
  },
};
</script>
