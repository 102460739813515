<template>
  <div class="header">
    <!-- YUCK!!! -->
    <img v-if="providerName === 'caliber'" class="header-img cal-img" :src="require('@/assets/halfmark-black.svg')" alt="" srcset="" />
    <img v-else-if="providerName === 'xperienceroofing'" class="header-img xp-img" :src="require('@/assets/xperience-logo.png')" alt="" srcset="" />
    <h1 v-else-if="providerName === 'lsh'" class="header-img lsh-img">Legacy Smart Home</h1>
    <img v-else-if="providerName === 'solarpros'" class="header-img solarpros-img" :src="require('@/assets/solarpros-logo.png')" alt=""/>
    <b-button
      v-if="isAuthenticated"
      class="logout-button"
      variant="outline-primary"
      size="sm"
      @click="logOut"
    >
      Logout
    </b-button>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: { isAuthenticated: { type: Boolean, required: true } },

  computed: {
    providerName() {
      // YUCK!!!
      if (location.hostname.match(/^xperienceroofing/)) {
        return 'xperienceroofing';
      }
      if (location.hostname.match(/^lsh/)) {
        return 'lsh';
      }
      if (location.hostname.match(/^solarpros/)) {
        return 'solarpros';
      }
      return 'caliber';
    }
  },

  methods: {
    ...mapActions({ logUserOut: "auth/logUserOut" }),

    logOut() {
      this.logUserOut();
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.header {
  padding: 10px 5px;
  display: grid;
}
.header-img {
  grid-column: 1;
  margin: auto auto;
}
.cal-img {
  width: 70vw;
  max-height: 38px;
}
.xp-img {
  max-height: 70px;
}
.lsh-img {
  font-size: 2.5em;
  font-family: monospace;
}

.solarpros-img {
  max-width: 80vw;
}

@media screen and (min-width: 575.98px) {
  .header {
    grid-template-columns: repeat(3, 1fr);
  }

  .header-img {
    grid-column: 2;
  }
  .cal-img {
    width: 40vw;
    max-height: 57px;
  }
  .xp-img {
    max-height: 80px;
  }
  .solarpros-img {
    max-width: 30vw;
  }
}

.logout-button {
  grid-column: 3;
  margin: auto auto;
  max-height: 40px;
  max-width: 100px;
}
</style>
