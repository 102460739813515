<template>
  <div>
    <!-- external_id type options -->
    <b-row>
      <b-col md="2" sm="12">
        <b-card title="External user id types">

          <b-input-group class="mt-3" :v-model="new_external_id_type">
            <b-form-input v-model="new_external_id_type" @keyup.enter="create_external_id_type"></b-form-input>
            <b-input-group-append>
              <b-button @click="create_external_id_type">Add</b-button>
            </b-input-group-append>
          </b-input-group>

          <b-list-group class="mt-3">
            <b-list-group-item v-for="ext_id in external_id_types" :key="ext_id.id" class="ext-id-type">{{ ext_id.name }}<b-icon icon="trash" class="delete-icon" @click="() => delete_external_id_type(ext_id.name)"></b-icon></b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import requestCommon from "../../components/requestCommon.vue";

export default {
  mixins: [requestCommon],
  data() {
    return {
      new_external_id_type: "",
      external_id_types: []
    };
  },
  computed: {
    ...mapGetters({adminClient: "api/adminClient"})
  },
  methods: {
    async create_external_id_type() {
      try {
        const {data: new_id_type} = await (this.adminClient.post("/configs/external-id-types", {name: this.new_external_id_type}));
        this.external_id_types.unshift(new_id_type);
        this.new_external_id_type = "";
      } catch(error) {
          this.handleRequestError(error);
      }
    },
    async fetch_external_id_types() {
      const { data: ext_ids } = await (this.adminClient.get("/configs/external-id-types"));
      this.external_id_types = ext_ids;
    },
    async delete_external_id_type(ext_id_type_name) {
      try {
        await this.adminClient.delete(`/configs/external-id-types/${encodeURIComponent(ext_id_type_name)}`);
        this.fetch_external_id_types();  // refresh type list
      } catch(error) {
        this.handleRequestError(error);
      }
     }
  },
  async created() {
    await this.fetch_external_id_types();
  },
};
</script>

<style scoped>
.ext-id-type {
  display: flex;
  justify-content: space-between;
}
.delete-icon {
  cursor: pointer;
}
</style>