<template>
  <b-card no-body>
    <template #header>
      <b-nav pills justified card-header small>
        <b-nav-item
          v-for="item in tabs"
          :key="item.text"
          class="ml-2"
          active-class="active"
          :to="item.to"
          >{{ item.text }}</b-nav-item
        >
      </b-nav>
    </template>
    <b-card-body>
      <router-view></router-view>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({ adminClient: "api/adminClient" }),

    tabs() {
      const tabs = [];
      if (this.features?.pending_pay_caliber_types) {
        tabs.unshift(
          {
            text: "Pending Pay Solar",
            to: {name: "uploadsPendingPayType", params: {pendingPayType: "solar"}},
          },
          {
            text: "Pending Pay CaliberSmart",
            to: {name: "uploadsPendingPayType", params: {pendingPayType: "calibersmart"}},
          },
        );
      } else {
        tabs.unshift(
          {
            text: "Pending Pay",
            to: {name: "uploadsPendingPay"},
          },
        );
      }
      tabs.unshift(
        {
          text: "Pay History",
          to: {name: "uploadsPayHistory"},
        },
      );
      // {
      //   text: "Overrides",
      //   to: {name: "uploadsOverrides"},
      // },
      return tabs;
    },
  },

  data() {
    return {
      features: null,
    };
  },

  methods: {
    async getNavFeatures() {
      const { data: features } = await this.adminClient.get("/nav/features");
      this.features = features;
    },
  },

  async beforeMount() {
    await this.getNavFeatures();
  },
};
</script>