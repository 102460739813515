<template>
  <div>
    <b-form-group label="Rep Info" label-cols-lg="auto">
      <b-row>
        <b-col cols="3">
        <b-form-group description="External User ID Type">
          <b-form-select type="text" v-model="repInfo.external_id_type.id" :options="external_id_types"></b-form-select>
        </b-form-group>
        </b-col>
        <b-col cols="9">
          <b-form-group description="External User ID">
            <b-form-input type="text" v-model="repInfo.external_id"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <label>Assigned supervisors</label>
      <div class="supervisor-assignments">
        <div class="add-supervisor">
          <b-form-input
            placeholder="add new supervisor"
            list="allsup-list"
            v-model="newSupervisor"
          ></b-form-input>
          <b-button
            class="mt-1"
            v-if="newSupervisor"
            variant="outline-primary"
            @click="addSupervisor"
            >add -></b-button
          >
          <b-alert variant="warning" :show="modifiedSups"
            >You must click "Save" for supervisor changes to take effect</b-alert
          >
          <datalist id="allsup-list">
            <option v-for="sup in allSupervisors" :key="sup.user_id">
              {{ sup.name }} &lt;{{ sup.email }}&gt;
            </option>
          </datalist>
        </div>
        <b-list-group class="ml-2">
          <b-list-group-item
            class="supervisor-item"
            v-for="sup in repInfo.supervisors"
            :key="sup.email"
          >
            <b-avatar :src="sup.picture_url"></b-avatar>
            <span class="ml-2 mr-2">{{ sup.name }}</span>
            <span>{{ sup.email }}</span>
            <b-button
              size="sm"
              variant="outline-secondary"
              class="ml-3"
              @click="() => removeSupervisor(sup.email)"
            >
              <b-icon icon="trash"></b-icon>
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import requestCommon from "../../../components/requestCommon";
import { mapGetters } from "vuex";

export default {
  mixins: [requestCommon],
  props: {
    userId: { type: String, default: "" },
    triggerRepSave: { type: Boolean, default: false },
  },
  watch: {
    async triggerRepSave(newValue) {
      if (newValue) {
        await this.updateRep();
        this.modifiedSups = false;
        this.$emit("doneLoading");
      }
    },
  },
  computed: {
    ...mapGetters({
      adminClient: "api/adminClient",
    }),
  },
  data() {
    return {
      external_id_types: [],
      repInfo: {
        external_id: null,
        external_id_type: {name: "", id: null},
        supervisors: [],
      },
      newSupervisor: null,
      allSupervisors: [],
      modifiedSups: false,
    };
  },
  methods: {
    async fetchSupervisors() {
      this.allSupervisors = (await this.adminClient.get("/users?roles=supervisor")).data;
    },

    async fetchRepInfo() {
      const {data: repInfo} = (await this.adminClient.get(`/user/${this.userId}/rep`));
      if (repInfo.external_id_type === null) {
        repInfo.external_id_type = {name: "", id: null};
      }
      this.repInfo = repInfo;
    },

    async updateRep() {
      if (!this.userId) {
        // it should always be set before this method is called
        this.toastActionStatus({
          title: "error saving rep info",
          message: "internal error (missing userId)",
          variant: "danger",
        });
        return;
      }
      try {
        const ext_id = this.repInfo.external_id.toString();
        if (!ext_id) {
          this.repInfo.external_id_type = {name: "", id: null};
        }
        await this.adminClient.put(`/user/${this.userId}/rep`, {
          external_id: ext_id,
          external_id_type: this.repInfo.external_id_type.id,
          supervisors: this.repInfo.supervisors || [],
        });
      } catch (error) {
        console.error(error);
        this.handleRequestError(error);
      }
    },

    addSupervisor() {
      const email = this.newSupervisor.match(/<(?<email>[^>]+)>$/)?.groups?.email;
      if (!email) {
        this.toastActionStatus({
          title: "Can't add Supervisor",
          message: "Invalid value given",
          variant: "danger",
        });
        return;
      }

      const foundSup = this.allSupervisors.find((s) => s.email === email);
      if (!foundSup) {
        this.toastActionStatus({
          title: "Can't add Supervisor",
          message: "Entered supervisor is not in the list of valid entries",
          variant: "danger",
        });
        return;
      }

      if (foundSup.user_id == this.userId) {
        this.toastActionStatus({
          title: "Can't add Supervisor",
          message: "A user can't be their own supervisor",
          variant: "danger",
        });
        return;
      }

      const alreadyAssignedSup = this.repInfo.supervisors.find((s) => s.email === foundSup.email);
      if (alreadyAssignedSup) {
        this.newSupervisor = null;
        return;
      }

      this.repInfo.supervisors.push(foundSup);
      this.newSupervisor = null;
      this.modifiedSups = true;
    },

    removeSupervisor(supervisorEmail) {
      const indx = this.repInfo.supervisors.findIndex((s) => s.email === supervisorEmail);
      this.repInfo.supervisors.splice(indx, 1);
      this.modifiedSups = true;
    },

    async fetch_external_id_types() {
      const { data: ext_ids } = await (this.adminClient.get("/configs/external-id-types"));
      this.external_id_types = ext_ids.map(id_type => ({value: id_type.id, text: id_type.name}));
      this.external_id_types.push({value: null, text: "select one", disabled: true})
    },
  },

  async created() {
  },

  async mounted() {
    const promises = [this.fetchSupervisors(), this.fetch_external_id_types()];
    if (this.userId) {
      promises.push(this.fetchRepInfo());
    }
    await Promise.all(promises);
    this.$emit("doneLoading");
  },
};
</script>

<style scoped>
.supervisor-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 850px) {
  .supervisor-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .supervisor-assignments {
    display: grid;
    grid-template-columns: auto auto;
  }

  .add-supervisor {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
</style>
