<template>
  <div id="app">
    <Header :isAuthenticated="isAuthenticated"></Header>
    <div class="body-container">
      <div v-if="isAuthenticated || isSSO">
        <router-view v-if="userIsLoaded || isSSO"></router-view>
        <b-spinner v-else variant="primary"></b-spinner>
      </div>
      <div v-else>
        <Login></Login>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Header from "./components/Header";
import Login from "./views/general/Login";

export default {
  components: { Header, Login },
  computed: {
    isAuthenticated() {
      return !!this.sessionToken;
    },
    isSSO() {
      // exception for user-login route
      const curRoute = this.$router.currentRoute;
      if (curRoute.path === "/user-login" && curRoute.query.token) {
        // let the UserLogin page handle things for us
        return true;
      }

      return false;
    },
    userIsLoaded() {
      return !!this.userName;
    },
    ...mapGetters({ sessionToken: "auth/sessionToken", userName: "auth/userName" }),
  },
  methods: {
    ...mapActions({ checkAuth: "auth/checkAuth" }),
  },
  beforeMount() {
    this.checkAuth();
  },
};
</script>

<style>
.body-container {
  margin: auto 10px;
}
@media screen and (min-width: 575.98px) {
  .body-container {
    margin: auto 50px;
  }
}
</style>
