<template>
  <div>
    <h2>{{ title }}</h2>
    {{ description }}
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "Something went wrong" },
    description: { type: String, default: "Sorry about that" },
  },
};
</script>
