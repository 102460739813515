const cookieName = "solar_payments";
const setUserCookie = (token) => {
  document.cookie = `${cookieName}=${token};secure;samesite=strict`;
};
const clearUserCookie = () => {
  document.cookie = `${cookieName}=;max-age=0`;
};

export default {
  namespaced: true,
  state: {
    googleClientId: "",
    sessionToken: "",
    userInfo: null,
    reps: [],
  },

  mutations: {
    setSessionToken(state, token) {
      state.sessionToken = token;
    },

    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },

    setReps(state, reps) {
      state.reps = reps;
    },
  },

  actions: {
    async checkAuth({ rootState, dispatch }) {
      /* eslint-disable no-useless-escape */
      const cookiePattern = new RegExp(`\s*;?\s*${cookieName}=(?<token>[^;]+)`);
      const parseResult = document.cookie.match(cookiePattern);

      if (parseResult && parseResult.groups) {
        const token = parseResult.groups.token;
        if (token) {
          // attempt api call with this token to determine its validity
          const api = rootState.api.client;
          let userInfo;

          try {
            const { data: user } = await api.get("/user", {
              headers: { Authorization: `Bearer ${token}` },
            });
            userInfo = user;
          } catch (error) {
            console.error(error);
            // token not valid
            dispatch("logUserOut");
            return;
          }

          // token is valid
          dispatch("logUserIn", { sessionToken: token, userInfo });
        }
      }
    },

    async logUserIn({ commit }, { sessionToken, userInfo }) {
      setUserCookie(sessionToken);
      if (userInfo.roles.find((r) => r === "admin")) {
        commit("api/createAdminClient", sessionToken, { root: true });
      } else {
        commit("api/createUserClient", sessionToken, { root: true });
      }
      commit("setSessionToken", sessionToken);
      commit("setUserInfo", userInfo);
    },

    logUserOut({ dispatch, commit }) {
      clearUserCookie();
      commit("api/clearClients", null, { root: true });
      commit("setSessionToken", "");
      commit("setUserInfo", null);
      dispatch("adminView/disable", null, { root: true });
    },
  },

  getters: {
    sessionToken(state) {
      return state.sessionToken;
    },

    userName(state) {
      return state.userInfo?.name;
    },

    userRoles(state) {
      return state.userInfo?.roles;
    },

    userInfo(state) {
      return state.userInfo;
    },

    effectiveUser(state, getters, rootState, rootGetters) {
      if (rootGetters["adminView/targetUser"]) {
        return rootGetters["adminView/targetUser"];
      }
      return state.userInfo;
    },
  },
};
