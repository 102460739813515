<template>
  <div></div>
</template>

<script>
export default {
  beforeRouteEnter(to, from, next) {
    next(async (vueInstance) => {
      const {
        query: { token },
      } = to;
      if (!token) {
        vueInstance.$router.push({
          name: "genericError",
          params: {
            title: "Invalid request",
            description: "You're doing it wrong",
          },
        });
        return;
      }

      const apiClient = vueInstance.$store.getters["api/client"];
      let sessionData;
      try {
        const { data } = await apiClient.post("/session", { sso_token: token });
        sessionData = data;
      } catch (err) {
        vueInstance.$router.push({
          name: "genericError",
          params: {
            title: "Invalid Session",
            description: "Session is expired or invalid",
          },
        });
        return;
      }

      const storeDispatch = vueInstance.$store.dispatch;
      await storeDispatch(
        "auth/logUserIn",
        {
          sessionToken: sessionData.token,
          userInfo: sessionData.user,
        },
        { root: true }
      );

      vueInstance.$router.replace({ name: "index" });
    });
  },
};
</script>
