<template>
  <div class="login-container">
    <div v-if="doneLoading">
      <div class="google-button-container" v-if="googleLogin.show">
        <p class="auth-error" v-if="authError">{{ authError }}</p>
        <LoginGoogle
          @loginSuccess="this.googleLoginSuccess"
          @loginFailure="this.googleLoginFailure"
          :clientId="this.googleLogin.params.clientId"
        ></LoginGoogle>
      </div>
      <div v-if="msLogin.show">
        <a
          v-if="!msLogin.account || !msLogin.token"
          @click="microsoftLogin"
          target="_blank"
          rel="noopener noreferrer"
          class="ms-button"
        >
          <img :src="require('@/assets/microsoft-button.svg')" alt="sign in with microsoft"/>
        </a>
        <div v-else>
          <a @click="() => this.authenticateUser({microsoft_token: this.msLogin.token})" target="_blank" rel="noopener noreferrer" class="ms-button ms-signedin-button">
            <img :src="require('@/assets/ms-logo.svg')"/>
            Signed in as {{ msLogin.account.name }}
          </a>
          <a class="ms-button ms-logout-button" @click="microsoftLogout">Click here to sign out of your Microsoft account</a>
        </div>
      </div>
    </div>
    <b-spinner variant="primary" v-else></b-spinner>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { PublicClientApplication } from '@azure/msal-browser';
import LoginGoogle from "./LoginGoogle";

export default {
  components: { LoginGoogle, PublicClientApplication },

  data() {
    return {
      doneLoading: false,
      authError: "",
      googleLogin: {
        show: false,
        params:  {
          clientId: "",
          fetch_basic_profile: true,
        },
      },
      msLogin: {
        show: false,
        msalConfig: { auth: { clientId: null } },
        account: null,
        token: null,
      },
    };
  },

  async beforeMount() {
    await this.fetchAuthState();
    if (this.msLogin.show) {
      this.$msalInstance = new PublicClientApplication(this.msLogin.msalConfig);
      await this.getMicrosoftToken();
    }
    this.doneLoading = true;
  },

  computed: { ...mapGetters({ apiClient: "api/client" }) },
  methods: {
    ...mapActions({ logUserIn: "auth/logUserIn" }),

    async fetchAuthState() {
      const { data } = await this.apiClient.get("/auth-state");
      if (data.google_login.enabled || this.$route.query.google) {
        this.googleLogin.params.clientId = data.google_login.client_id;
        this.googleLogin.show = true
      }
      if (data.microsoft_login.enabled || this.$route.query.microsoft) {
        this.msLogin.msalConfig.auth.clientId = data.microsoft_login.client_id;
        this.msLogin.show = true;
      }
    },

    /* Callback function for the Google login button. Called after Google authenitcation
     * succeeds.
     * Its primary job is to request a session token from the API, but it also has the side-effect
     * of populating userInfo in our application state. */
    async googleLoginSuccess(jwtToken) {
      await this.authenticateUser({google_token: jwtToken});
    },

    /* Callback function for the google login button. Called when Google authentication fails, or
     * when the googleLoginSuccess() function fails. */
    googleLoginFailure(error) {
      // todo prints 'object' object... as 'error'
      this.authError = `Google Login failed: ${error}`;
    },

    async microsoftLogin() {
      this.doneLoading = false;
      if (!this.msLogin.token|| !this.msLogin.account) {
        try {
          await this.$msalInstance.loginPopup({});
        } catch (error) {
            if (error.indexOf("Interaction is currently in progress") !== 1) {

            }
            console.error(error);
            let err = error.response?.data;
            this.authError = err || error;
            this.doneLoading = true;
            return;
        }
        // sets: this.msLogin.token -- the logic coupling is ugly I know...
        await this.getMicrosoftToken();
      }
      await this.authenticateUser({microsoft_token: this.msLogin.token});
    },

    async getMicrosoftToken() {
      let accountList = [];
      try {
        accountList = await this.$msalInstance.getAllAccounts();
      } catch (error) {
        let err = error.response?.data;
        this.authError = err || error;
        this.doneLoading = true;
        return;
      }

      if (accountList.length === 0) {
        return;
      }
      if (accountList.length > 1) {
        console.error("more than one MS account found!?");
      }

      this.msLogin.account = accountList[0];
      let token_resp;
      try {
        token_resp = await this.$msalInstance.acquireTokenSilent({account: this.msLogin.account});
        // NOTE consider if the resulting token is expired:
        // const token_resp = await this.$msalInstance.acquireTokenSilent({
        //   scopes: ["User.Read"],
        //   account: accountList[0],
        //   forceRefresh: true,
        // });
      } catch (error) {
        let err = error.response?.data;
        this.authError = err || error;
        this.doneLoading = true;
        return;
      }
      this.msLogin.token = token_resp.idToken;
      const tokenExp = token_resp.idTokenClaims.exp;
      const epochNow = parseInt(Date.now()/1000);
      console.log("token_exp", tokenExp, ">", epochNow);

      return this.msLogin.token;
    },

    async authenticateUser(session_req) {
      this.doneLoading = false;
      let sessionData;
      try {
        // attempt to create a session for our app from the google token
        const { data } = await this.apiClient.post("/session", session_req);
        sessionData = data;
      } catch (error) {
        let err = error.response?.data;
        this.authError = err || error;
        this.doneLoading = true;
        return;
      }

      // user is authorized

      this.authError = ""; // reset any previously reported errors
      this.logUserIn({
        sessionToken: sessionData.token,
        userInfo: sessionData.user,
      }); // save the session data in app state
      this.doneLoading = true;
    },

    async microsoftLogout() {
      try {
        await this.$msalInstance.logoutPopup({});
        this.authError = "";
      } catch (error) {
        let err = error.response?.data;
        this.authError = err || error;
      }
      this.msLogin.account = null;
      this.msLogin.token = null;
      this.doneLoading = true;
      return;
    },
  },
};
</script>

<style scoped>
.login-container {
  margin-top: 30px;
  text-align: center;
}
.google-button-container {
  margin: 0px auto;
  width: 181px;
  height: 52px;
  display: block;
}
.ms-button {
  display: block;
  width: 215px;
  margin: 10px auto;
  cursor: pointer;
}
.ms-signedin-button {
  height: 41px;
  text-align: center;
  border-color: gray;
  border-style: solid;
  border-width: .5px;
  line-height: 2.2em;
  text-decoration: none;
}
.ms-logout-button {
  height: 50px;
  text-align: center;
  border-color: gray;
  border-style: solid;
  border-width: .5px;
  text-decoration: none;
}
.auth-error {
  width: 70%;
  margin: 10px auto;
}
</style>