<template>
  <div id="google-login-button"></div>
</template>

<script>
export default {
  props: {
    clientId: {required: true, type: String}
  },
  methods: {
    handleCredentialResponse(response) {
      this.$emit("loginSuccess", response.credential);
    },
    async importGoogleClient() {
      if (!window.google) {
        await new Promise((resolve, reject) => {
          const script = document.createElement("script");
          script.setAttribute("src", "https://accounts.google.com/gsi/client");
          document.documentElement.appendChild(script);
          script.onload = () => {resolve()};
        });
      }
    },
  },
  async mounted() {
    await this.importGoogleClient()

    window.google.accounts.id.initialize({
      client_id: this.clientId,
      callback: this.handleCredentialResponse
    });
    window.google.accounts.id.renderButton(
      document.getElementById("google-login-button"),
      { theme: "outline", size: "large" }  // customization attributes
    );
    window.google.accounts.id.prompt(); // also display the One Tap dialog
  },
}
</script>