<template>
  <div>
    <div class="product-select">
      <input
        v-if="editingProduct"
        v-model="currentProduct.product_name"
        @blur="
          editingProduct = false;
          updateProductName();
        "
        @keyup.enter="
          editingProduct = false;
          updateProductName();
        "
        v-focus
      />
      <h3 id="product-header" v-else>
        <label @click="editingProduct = true"> {{ currentProduct.product_name }} </label>
      </h3>
      <select
        name="chooseProduct"
        :value="currentProduct.product_id"
        ref="product-list"
        @change="changeProduct($event)"
      >
        <option v-for="product in products" :value="product.product_id" :key="product.product_name">
          {{ product.product_name }}
        </option>
      </select>
      <b-button id="save-button" @click="saveTableState">Save</b-button>
    </div>
    <hr />
    <template>
      <div class="product-tables">
        <div class="display">
          <b-form inline @submit="createProduct" label="Enter product name">
            <b-form-input
              id="input-1"
              v-model="productAdd"
              placeholder="Enter Product Name"
              trim
            ></b-form-input>
            <b-button type="submit" value="Submit">Add Product</b-button>
          </b-form>
          <b-spinner v-if="currentProductProcessing" variant="primary"></b-spinner>

          <b-table-simple striped hover caption-top>
            <caption>
              Applied
            </caption>
            <b-thead class="thead-dark">
              <b-tr>
                <b-th scope="col">Column</b-th>
                <b-th scope="col">Format</b-th>
              </b-tr>
            </b-thead>
            <Draggable :list="currentProduct.fields" tag="tbody" group="products">
              <b-tr v-for="item in currentProduct.fields" :key="item.field_name">
                <b-td>{{ item.field_name }}</b-td>
                <b-td>
                  <select
                    name="rowFormat"
                    id="format"
                    @change="changeFormat($event, item)"
                    :value="item.field_format"
                  >
                    <option value="string">General</option>
                    <option value="money">Money</option>
                    <option value="decimal">Decimal</option>
                    <option value="number">Number</option>
                    <option value="date">Date</option>
                  </select>
                </b-td>
              </b-tr>
            </Draggable>
          </b-table-simple>
        </div>

        <div class="hide">
          <b-form inline id="fieldset-1" @submit="createField" label="Enter field name">
            <b-form-input
              id="input-1"
              v-model="fieldAdd"
              placeholder="Enter Field Name"
              trim
            ></b-form-input>
            <b-button type="submit" value="Submit">Add Field</b-button>
          </b-form>
          <b-spinner v-if="fieldsListProcessing" variant="primary"></b-spinner>

          <b-table-simple striped hover caption-top sort-by="Column">
            <caption>
              Free Fields
            </caption>
            <b-thead class="thead-dark">
              <b-tr>
                <b-th scope="col">Column</b-th>
                <b-th scope="col">Format</b-th>
              </b-tr>
            </b-thead>
            <Draggable :list="fields" tag="tbody" group="products">
              <b-tr v-for="item in fields" :key="item.field_name">
                <b-td>{{ item.field_name }}</b-td>
                <b-td>
                  <select name="rowFormat" id="format" disabled value="string">
                    <option value="string">General</option>
                    <option value="money">Money</option>
                    <option value="decimal">Decimal</option>
                    <option value="number">Number</option>
                    <option value="date">Date</option>
                  </select>
                </b-td>
              </b-tr>
            </Draggable>
          </b-table-simple>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Draggable from "vuedraggable";
export default {
  name: "adminProducts",
  components: { Draggable },
  data() {
    return {
      editingProduct: false,
      currentProduct: {},
      products: [],
      productErrors: [],
      productAdd: "",
      currentProductProcessing: false,

      fieldsListProcessing: false,
      fieldErrors: [],
      fields: [],
      fieldAdd: "",
    };
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  methods: {
    async updateProductName() {
      if (this.currentProduct.product_name.length == 0) {
        this.toastActionStatus({
          title: "Empty Product Name",
          message: "Product name is empty, page reloading.",
          variant: "danger",
          delay: 5000,
        });
        setTimeout(function () {
          window.location.reload();
        }, 1000);
        return;
      }
      try {
        const {
          data: { product },
        } = await this.adminClient.put(`/lineitem-products/${this.currentProduct.product_id}`, {
          product_name: this.currentProduct.product_name.toUpperCase(),
        });
        this.currentProduct = product;
        this.$refs["product-list"].value = this.currentProduct.product_id;
        const indexofproduct = this.products.findIndex(
          (findproduct) => findproduct["product_id"] == product["product_id"]
        );
        this.products.splice(indexofproduct, 1, product);
        this.toastActionStatus({
          title: "Changed Product Name",
          message: "success",
          variant: "success",
          delay: 3000,
        });
      } catch (error) {
        this.handleRequestError(error);
      }
    },
    async createProduct() {
      try {
        const {
          data: { product },
        } = await this.adminClient.post("/lineitem-products", {
          product_name: this.productAdd.toUpperCase(),
        });
        this.products.splice(0, 0, product);
        this.currentProduct = product;
        this.$refs["product-list"].value = this.currentProduct.product_id;
        this.productAdd = "";
        this.toastActionStatus({
          title: "Created Product",
          message: "success",
          variant: "success",
          delay: 3000,
        });
      } catch (error) {
        this.handleRequestError(error);
      }
    },
    async createField() {
      try {
        const {
          data: { field },
        } = await this.adminClient.post("/lineitem-products/fields", {
          field_name: this.fieldAdd.toUpperCase(),
        });
        this.fieldAdd = "";
        this.toastActionStatus({
          title: "Created Field",
          message: "success",
          variant: "success",
          delay: 3000,
        });
        this.fields.splice(0, 0, field);
      } catch (error) {
        this.handleRequestError(error);
      }
    },
    changeProduct(evt) {
      const product = this.products.find((item) => item.product_id == evt.target.value);
      this.getProductInfo(evt.target.value);
      this.currentProduct = product;
    },
    async getProductInfo(product_id) {
      this.currentProductProcessing = true;
      try {
        const {
          data: { product },
        } = await this.adminClient.get(`/lineitem-products/${product_id}`);

        this.currentProduct = product;

        this.currentProduct.fields.sort(function (a, b) {
          return a.index - b.index;
        });
      } catch (error) {
        this.handleRequestError(error);
      }
      this.currentProductProcessing = false;
      this.fetchFields();
    },

    changeFormat: function (evt, element) {
      var row = this.currentProduct.fields.find((item) => item.field_name == element.field_name);
      row.field_format = evt.target.value;
    },
    async saveTableState() {
      let api_fields = [];
      this.currentProduct.fields.map((field, index) => {
        let field_obj = {};
        field_obj["field_format"] = field["field_format"];
        field_obj["field_name"] = field["field_name"];
        field_obj["index"] = index;
        api_fields.splice(index, 0, field_obj);
      });
      try {
        await this.adminClient.put(
          `/lineitem-products/${this.currentProduct["product_id"]}/fields`,
          {
            fields: api_fields,
          }
        );
        this.toastActionStatus({
          title: "Save Table",
          message: "success",
          variant: "success",
          delay: 3000,
        });
      } catch (error) {
        this.handleRequestError(error);
      }
    },
    async fetchProducts() {
      try {
        const {
          data: { products },
        } = await this.adminClient.get("/lineitem-products");
        this.products = products;
        if (this.products.length > 0) {
          this.getProductInfo(this.products[0].product_id);
        }
      } catch (error) {
        this.handleRequestError(error);
      }
    },
    async fetchFields() {
      this.fieldsListProcessing = true;
      try {
        const {
          data: { fields },
        } = await this.adminClient.get("/lineitem-products/fields");
        var filtered = [];
        if (this.currentProduct["fields"].length > 0) {
          const arrofnames = [];
          this.currentProduct["fields"].map((field) => arrofnames.push(field["field_name"]));
          filtered = fields.filter((field) => !arrofnames.includes(field["field_name"]));
        } else {
          filtered = fields;
        }
        this.fields = filtered.sort(function (a, b) {
          var nameA = a.field_name.toUpperCase();
          var nameB = b.field_name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      } catch (error) {
        this.handleRequestError(error);
      }
      this.fieldsListProcessing = false;
    },
    handleRequestError(error) {
      console.error(error);
      const respStatus = error.response?.status;
      const errorMessages = {
        [respStatus]: "request failed for an unknown reason",
        400: "there was something wrong with your request",
        401: "it appears your session is expired. Log in again",
        403: "you do not have access to upload files",
        500: "unexpected server error",
      };
      const errorMessage = errorMessages[respStatus];
      this.toastActionStatus({
        title: "Request failed",
        message: `Error ${errorMessage}: ${JSON.stringify(error?.response?.data)}`,
        variant: "danger",
        delay: 15000,
      });
    },

    toastActionStatus({ title, message, variant, delay }) {
      this.$root.$bvToast.toast(message, {
        title: title || "",
        variant: variant || "primary",
        autoHideDelay: delay || 10000,
        solid: true,
      });
    },
  },
  computed: {
    ...mapGetters({
      adminClient: "api/adminClient",
    }),
  },
  mounted() {
    this.fetchProducts();
  },
};
</script>

<style>
/* headers */
.product-select {
  display: grid;
  grid-template-columns: 45vw 20vw 20vw;
}
#product-header {
  grid-column: 1;
}
#product-list {
  grid-column: 2;
  padding: 5px;
}
#save-button {
  grid-column: 3;
  padding: 5px;
}
/* tables */
.product-tables {
  display: grid;
}

.display {
  grid-row-start: 1;
  grid-row-end: 1;
  padding: 5px;
}
.hide {
  grid-row-start: 1;
  grid-row-end: 1;
  padding: 5px;
}
</style>
