<template>
  <div>
    <b-spinner v-if="loading" variant="primary"></b-spinner>
    <div v-else-if="lineitems.length">
      <Lineitems :lineitems="lineitems" :includePaydate="true"></Lineitems>
    </div>
    <div v-else>Your search yielded no results...</div>
  </div>
</template>

<script>
import moment from "moment";
import { adminViewUserRouteMap } from "../routes";
import { mapGetters, mapMutations } from "vuex";
import requestCommon from "../components/requestCommon";
import Lineitems from "./Lineitems";

export default {
  /* if no searchStr is set, redirect to previous page
   */
  beforeRouteEnter(to, from, next) {
    next((vueInstance) => {
      const searchStr = vueInstance.$store.getters["search/lineitemSearchStr"];
      if (!searchStr) {
        // send them back to the pay date list page

        // TODO don't love that we're using the existence of repUserId to determine if a supervisor is viewing...
        const repUserId = to.params?.repUserId;
        const routeName = repUserId ? "repPay" : "pay";
        const props = repUserId ? { repUserId } : {};
        vueInstance.$router.push({ name: adminViewUserRouteMap[routeName], props });
      }
    });
  },
  mixins: [requestCommon],
  props: {
    repUserId: { type: String, default: null },
  },
  computed: {
    ...mapGetters({
      authedClient: "api/authedClient",
      lineitemSearch: "search/lineitemSearchStr",
    }),
  },
  components: { Lineitems },
  data() {
    return {
      loading: true,
      searchStr: "",
      lineitems: [],
    };
  },

  methods: {
    ...mapMutations({
      setSearchStr: "search/setLineitemSearchStr",
    }),
    async getResults() {
      this.loading = true;
      this.searchStr = this.lineitemSearch;
      if (!this.searchStr) {
        return;
      }
      try {
        const params = {};
        if (this.repUserId) {
          params["user_id"] = this.repUserId;
        }
        params["search_str"] = this.searchStr;
        const searchResults = (await this.authedClient.get("/pay-history/lineitems", { params }))
          .data;
        this.lineitems = searchResults.map(l => ({...l, pay_date: moment(l.pay_date).format("M/D/YYYY")}));
      } catch (error) {
        this.handleRequestError(error);
      }
      this.setSearchStr("");
      this.loading = false;
    },
  },

  mounted() {
    this.getResults();
  },
};
</script>
