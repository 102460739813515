<template>
  <div class="payment-search">
    <div v-if="loading" class="text-center">
      <b-spinner variant="primary"></b-spinner>
    </div>
    <b-form-group v-else label="Search for Payments">
      <b-input-group>
        <b-form-input @keyup.enter="submitSearch" v-model="searchString"></b-form-input>
        <b-input-group-append>
          <b-button variant="outline-primary" @click="submitSearch" :disabled="!searchString">
            <b-icon icon="search"></b-icon>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import requestCommon from "./requestCommon";
import { adminViewUserRouteMap } from "../routes";

export default {
  mixins: [requestCommon],
  props: { repUserId: { type: String, default: null } },
  computed: {
    isAdminView() {
      return this.$router.currentRoute.matched.find((route) => route.name === "adminViewUser");
    },
  },
  data() {
    return {
      loading: false,
      searchString: "",
    };
  },
  methods: {
    ...mapMutations({
      setSearchStr: "search/setLineitemSearchStr",
    }),
    async submitSearch() {
      await this.setSearchStr(this.searchString);
      // TODO don't love that we're using the existence of repUserId to determine if a supervisor is viewing...
      const routeName = this.repUserId ? "repSearchResults" : "searchResults";
      const props = this.repUserId ? { repUserId: this.repUserId } : {};

      if (this.isAdminView) {
        this.$router.push({ name: adminViewUserRouteMap[routeName], props });
      }
      else {
        this.$router.push({ name: routeName, props});
      }
    },
  },
};
</script>

<style scoped>
.payment-search {
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  padding: 10px;
  margin: 3px auto;
}
</style>
