<template>
  <div>
    <b-navbar toggleable="sm" type="light">
      <b-navbar-toggle target="admin-nav-collapse"></b-navbar-toggle>
      <b-collapse id="admin-nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item
            v-for="item in navLinks"
            :key="item.display"
            class="ml-2"
            active-class="active"
            :to="item.to"
            >{{ item.display }}</b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: { ...mapGetters({ userRoles: "auth/userRoles" }) },
  data() {
    return {
      navLinks: [
        // Admin
        { display: "Site Configuration", to: { name: "configuration" } },
        { display: "Uploads", to: { name: "uploadsPayHistory" } },
        { display: "Users", to: { name: "adminUsers" } },
        { display: "Products", to: { name: "adminProducts" } },
      ],
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vueInstance) => {
      if (!vueInstance.userRoles.find((r) => r === "admin")) {
        vueInstance.$router.push({ name: "index" });
      }
    });
  },
};
</script>

<style scoped>
.nav {
  margin-bottom: 15px;
}
</style>