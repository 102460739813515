<template>
  <div>
    <b-spinner v-if="loading" variant="primary"></b-spinner>
    <div v-else-if="pendingLineitems.length">
      <b-alert variant="info">Pending Pay expected: {{ pendingPayDate }}</b-alert>
      <LineitemsPending
        :pendingLineitems="pendingLineitems"
        :pendingPayDate="pendingPayDate"
        :pendingTotal="pendingTotal"
      ></LineitemsPending>
    </div>
    <p v-else>No payment items to display</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LineitemsPending from "./LineitemsPending";

export default {
  components: { LineitemsPending },

  computed: { ...mapGetters({ authedClient: "api/authedClient" }) },

  watch: {
    $route(to, from) {
      if (to.params.pendingPayType !== from.params.pendingPayType)
        this.fetchLineitemsPendingItems(to.params.pendingPayType);
    },
  },

  data() {
    return {
      loading: true,
      pendingLineitems: [],
      pendingPayDate: "",
      pendingTotal: 0,
    };
  },

  methods: {
    async fetchLineitemsPendingItems() {
      const params = {};

      const pendingPayType = this.$route.params.pendingPayType;

      if (pendingPayType)
        params.pay_type = pendingPayType;

      const {
        data: {
          pending_lineitems: pendingLineitems,
          pending_pay_date: pendingPayDate,
          pending_total: pendingTotal,
        },
      } = await this.authedClient.get("/pending-pay", { params });

      this.pendingLineitems = pendingLineitems;
      this.pendingPayDate = pendingPayDate;
      this.pendingTotal = pendingTotal;
      this.loading = false;
    },
  },

  beforeMount() {
    this.fetchLineitemsPendingItems();
  },

};
</script>