<template>
  <div>
    <b-spinner v-if="loading" variant="primary"></b-spinner>
    <Reps v-else :reps="reps"></Reps>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Reps from "./Reps";

export default {
  components: { Reps },
  computed: { ...mapGetters({ authedClient: "api/authedClient" }) },

  data() {
    return { reps: [], loading: true };
  },

  methods: {
    async fetchReps() {
      this.reps = (await this.authedClient.get("/pay-history/reps")).data;
      this.loading = false;
    },
  },

  mounted() {
    this.fetchReps();
  },
};
</script>
