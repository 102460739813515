<template>
  <div class="pdf-export">
      <div v-if="loading" class="text-center">
        <b-spinner variant="primary"></b-spinner>
      </div>
      <b-form-group v-else label="PDF Export">
        <b-form inline>
          <b-form-datepicker placeholder="Start date" id="start_date" v-model="pdf_startdate" class="mr-2 mb-2"></b-form-datepicker>
          <b-form-datepicker placeholder="End date" id="end_date" v-model="pdf_enddate" class="mr-1 mb-2"></b-form-datepicker>
          <b-button variant="secondary" @click="downloadPDF" :disabled="!pdf_startdate || !pdf_enddate">Download PDF</b-button>
        </b-form>
      </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import requestCommon from "./requestCommon";

export default {
  mixins: [requestCommon],
  computed: { ...mapGetters({ authedClient: "api/authedClient" }) },
  data() {
      return {
        loading: false,
        pdf_startdate: null,
        pdf_enddate: null,
      };
  },
  methods: {
    async downloadPDF() {
      this.loading = true;
      try {
        const resp = await this.authedClient.get('pay-history/download', {responseType: 'blob', params: {start_date: this.pdf_startdate, end_date: this.pdf_enddate}});

        if (resp.status === 204) {
          this.toastActionStatus({
            title: "No data found",
            message: "No data found for the given date range",
            variant: "info",
            delay: 15000,
          });
        } else {
          const found = resp.headers["content-disposition"].match(/;\s*filename\s*=\s*(?<name>.+)/);
          const fileName = found.groups.name;

          // html anchor element to click
          const link = document.createElement('a');

          // file link in browser's memory
          link.href = URL.createObjectURL(resp.data);

          // link.download = label;
          link.setAttribute('download', fileName);
          link.click();

          // cleanup
          URL.revokeObjectURL(link.href);
        }
      } catch (error) {
        console.log(error);
        this.handleRequestError(error);
      }
      this.loading = false;
    },
  },
}
</script>

<style scoped>
.pdf-export {
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  padding: 10px;
  margin: 3px auto;
}

</style>