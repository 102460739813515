<template>
  <b-container fluid>
    <b-card class="mt-3">
      <div>
        <b-spinner v-if="loading" variant="primary"></b-spinner>
        <div v-show="!loading">
          <UserDetailsForm
            :userId="topicUserId"
            :triggerUserSave="triggerUserSave"
            @doneLoading="userReady"
            @enableRepRole="setRepStatus"
            @userCreated="newUserCreated"
          ></UserDetailsForm>
          <RepDetailsForm
            v-if="repEnabled"
            :userId="topicUserId"
            :triggerRepSave="triggerRepSave"
            @doneLoading="repReady"
          ></RepDetailsForm>
          <b-button variant="primary" @click="save"> Save </b-button>
        </div>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import UserDetailsForm from "./components/UserDetailsForm";
import RepDetailsForm from "./components/RepDetailsForm";

export default {
  components: { UserDetailsForm, RepDetailsForm },
  data() {
    return {
      topicUserId: this.userId,
      userLoading: true,
      repLoading: true,
      triggerUserSave: false,
      triggerRepSave: false,
      repEnabled: false,
      triggeredNewUser: false,
    };
  },
  props: { userId: { type: String, default: "" } },
  computed: {
    ...mapGetters({
      adminClient: "api/adminClient",
    }),
    loading() {
      return this.userLoading || (this.repEnabled && this.repLoading);
    },
  },

  methods: {
    setRepStatus(repEnabled) {
      this.repEnabled = repEnabled;
    },

    userReady() {
      this.userLoading = false;
      this.triggerUserSave = false;
    },

    repReady() {
      this.repLoading = false;
      this.triggerRepSave = false;

      // this marks the end of the execution path for creating a new user, emit an event
      if (this.triggeredNewUser) {
        this.$emit("newUserCreated");
        this.triggeredNewUser = false;
      }
    },

    save() {
      this.userLoading = true;
      if (this.topicUserId) {
        // trigger save actions on child components
        this.triggerUserSave = true;
        this.triggerRepSave = true;
      } else {
        // in this case we wait for the user save event before triggering rep save. rep save is
        // triggered in newUserCreated() which is called after user save is complete.
        this.triggerUserSave = true;
        this.triggeredNewUser = true;
      }
    },

    newUserCreated(newUserId) {
      this.topicUserId = newUserId; // also updates the prop on RepDetailsForm component
      this.triggerRepSave = true; // indicates that it's time to save the rep with the new userId
    },
  },
};
</script>
