<template>
  <div>
    <Nav></Nav>
    <!--<PPAckModal></PPAckModal>-->
    <router-view></router-view>
  </div>
</template>

<script>
import Nav from "./User/Nav";
// import PPAckModal from "../components/PPAckModal";

export default {
  components: {
    Nav,
    // PPAckModal,
  },
};
</script>