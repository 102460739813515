import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;

// Add axios
import Axios from "axios";
Vue.prototype.$axios = Axios;
Vue.prototype.$getImgSrc = (asset) => require(`@/assets/${asset}`);

// Add Vuex
import Vuex from "vuex";
Vue.use(Vuex);
import auth from "./store/auth.js";
import api from "./store/api.js";
import adminView from "./store/adminView.js";
import search from "./store/search.js";
const store = new Vuex.Store({
  modules: { auth, api, adminView, search },
});

// Add VueRouter
import VueRouter from "vue-router";
import Routes from "./routes.js";
Vue.use(VueRouter);

// Add VueBootstrap
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false;

// For MS auth
Vue.prototype.$msalInstance = {};

new Vue({
  render: (h) => h(App),
  router: new VueRouter({ routes: Routes }),
  store,
}).$mount("#app");