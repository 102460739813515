<template>
  <div>
    <b-alert variant="info">Pending Pay expected: {{ pendingPayDate }}</b-alert>
    <LineitemsPending
      :pendingLineitems="pendingLineitems"
      :pendingPayDate="pendingPayDate"
      :pendingTotal="pendingTotal"
    ></LineitemsPending>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LineitemsPending from "./LineitemsPending";

export default {
  components: { LineitemsPending },

  props: { repUserId: { type: String, required: true } },

  computed: { ...mapGetters({ authedClient: "api/authedClient" }) },

  watch: {
    $route(to, from) {
      if (to.params.pendingPayType !== from.params.pendingPayType)
        this.fetchLineitemsPendingItems(to.params.pendingPayType);
    },
  },

  data() {
    return {
      pendingLineitems: [],
      pendingPayDate: "",
      pendingTotal: 0,
    };
  },

  methods: {
    async fetchLineitemsPendingItems() {
      const params = {};

      const pendingPayType = this.$route.params.pendingPayType;

      if (pendingPayType)
        params.pay_type = pendingPayType;

      const {
        data: {
          pending_lineitems: pendingLineitems,
          pending_pay_date: pendingPayDate,
          pending_total: pendingTotal,
        },
      } = await this.authedClient.get(`/pending-pay/${this.repUserId}`, { params });

      this.pendingLineitems = pendingLineitems;
      this.pendingPayDate = pendingPayDate;
      this.pendingTotal = pendingTotal;
    },
  },

  beforeMount() {
    this.fetchLineitemsPendingItems();
  },
};
</script>