import Axios from "axios";

const apiBasePath = "/api/v1";

export default {
  namespaced: true,
  state: {
    client: Axios.create({ baseURL: apiBasePath }),
    userClient: null,
    adminClient: null,
  },

  getters: {
    client(state) {
      return state.client;
    },

    authedClient(state) {
      if (state.userClient === null) {
        throw "Authenticated API called without being initialized first";
      }

      return state.userClient;
    },

    adminClient(state) {
      if (state.adminClient === null) {
        throw "Authenticated API called without being initialized first";
      }
      return state.adminClient;
    },
  },

  mutations: {
    createAdminClient(state, token) {
      state.adminClient = Axios.create({
        baseURL: apiBasePath,
        headers: { Authorization: `Bearer ${token}` },
      });
    },

    createUserClient(state, token) {
      state.userClient = Axios.create({
        baseURL: apiBasePath,
        headers: { Authorization: `Bearer ${token}` },
      });
    },

    clearUserClient(state) {
      state.userClient = null;
    },

    clearClients(state) {
      state.adminClient = null;
      state.userClient = null;
    },
  },
};
