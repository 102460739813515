<template>
  <div>
    <div>
      <div class="pending-pay-delete">
        <b-spinner v-if="clearPendingPayProcessing" variant="danger"></b-spinner>
        <b-button v-else @click="clearPendingPay" variant="outline-danger">Clear</b-button>
      </div>
    </div>
    <b-spinner v-if="pendingPayProcessing" variant="primary"></b-spinner>
    <b-form v-else validated>
      <b-form-group
        label="Pending Pay Date: "
        description="Date the pending payments can be expected"
        label-for="date-pending-pay"
      >
        <b-form-datepicker
          id="date-pending-pay"
          v-model="pendingPayDate"
          :state="!!pendingPayDate"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          locale="en"
        ></b-form-datepicker>
      </b-form-group>

      <b-form-group
        id="upload-pending-pay"
        label="Pending Pay File: "
        label-for="file-pending-pay"
      >
        <b-form-file
          id="file-pending-pay"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          v-model="pendingPayFile"
          required
        >
        </b-form-file>
      </b-form-group>

      <b-button
        variant="primary"
        @click="uploadPendingPay"
        :disabled="!(pendingPayFile && pendingPayDate)"
      >
        <b-icon icon="cloud-upload-fill"></b-icon> Upload
      </b-button>
    </b-form>
    <b-table
      class="mt-4"
      striped
      stacked="md"
      hover
      primary-key="batch_id"
      :fields="pendingBatchListFields"
      :items="pendingBatchList"
    >
      <template #cell(actions)="row">
        <b-button variant="secondary" @click="deletePendingPayBatch(row)">
          <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
        </b-button>
      </template>
    </b-table>
    <div v-if="pendingBatchList.length < 1">No uploads yet</div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import requestCommon from "../../../components/requestCommon.vue";
import { mapGetters } from "vuex";

export default {
  mixins: [requestCommon],

  computed: {
    ...mapGetters({adminClient: "api/adminClient"}),
  },

  watch: {
    $route(to, from) {
      this.fetchPendingPayBatches();
    },
  },

  data() {
    return {
      pendingPayDate: "",
      pendingPayFile: null,

      pendingPayProcessing: false,
      clearPendingPayProcessing: false,

      pendingBatchListProcessing: true,
      pendingBatchList: [],
      pendingBatchListFields: [
        { key: "upload_date", label: "Upload Date" },
        { key: "upload_file", label: "File Name" },
        { key: "actions", label: "Delete" },
        { key: "batch_id", thClass: "d-none", tdClass: "d-none" },
      ],
    };
  },

  methods: {
    // TODO, add to requestsCommon to share among upload components
    uploadResponseStatusHandlers(prefix) {
      const handlers = {
        400: "there was something wrong with your request",
        401: "it appears your session is expired. log in again",
        403: "you do not have access to upload files",
        409: "duplicate entry",
        500: "unexpected server error",
        404: "user not found",
      };

      if (prefix) {
        for (let key in Object.keys(handlers)) {
          handlers[ key ] = `${prefix} ${handlers[ key ]}`;
        }
      }

      return handlers;
    },

    async clearPendingPay() {
      this.clearPendingPayProcessing = true;

      const params = {};
      const pay_type = this.$route.params.pendingPayType;

      if (pay_type) {
        params.pay_type = pay_type;
      }

      try {
        await this.adminClient.delete("/pending-pay/batches", { params });
      } catch (error) {
        this.handleRequestError(error);
      }
      await this.fetchPendingPayBatches();
      this.clearPendingPayProcessing = false;
    },

    async uploadPendingPay() {
      const formData = new FormData();
      formData.append("file", this.pendingPayFile);
      formData.append("date", this.pendingPayDate);

      const pay_type = this.$route.params.pendingPayType;
      if (pay_type)
        formData.append("pay_type", pay_type);

      this.pendingPayProcessing = true;

      try {
        await this.adminClient.post("/pending-pay/batches", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        this.toastActionStatus({
          title: "Upload",
          message: "success",
          variant: "success",
          delay: 3000,
        });
      } catch (error) {
        console.log(error);
        this.handleRequestError(
          error, this.uploadResponseStatusHandlers(`[ ${this.pendingPayFile.name} ]`)
        );
      }
      await this.fetchPendingPayBatches();
      this.pendingPayFile = null;
      this.pendingPayProcessing = false;
    },

    async fetchPendingPayBatches() {
      this.pendingBatchListProcessing = true;

      const params = {};
      const pay_type = this.$route.params.pendingPayType;

      if (pay_type) {
        params.pay_type = pay_type;
      }

      try {
        const {
          data: { batches, pending_pay_date },
        } = await this.adminClient.get("/pending-pay/batches", { params });
        this.pendingBatchList = batches.map((row) => ({
          ...row,
          upload_date: moment
            .utc(row.upload_date)
            .tz(moment.tz.guess())
            .format("M-D-YYYY hh:mm:ss a"),
        }));
        this.pendingPayDate = pending_pay_date;
      } catch (error) {
        this.handleRequestError(error);
      }

      this.pendingBatchListProcessing = false;
    },

    async deletePendingPayBatch(row) {
      const {
        item: { upload_file: uploadFile, batch_id: batchId },
      } = row;

      if (confirm(`are you sure you want to delete ${uploadFile}`)) {
        try {
          await this.adminClient.delete(`/pending-pay/batches/${batchId}`);
          this.toastActionStatus({
            title: "Delete",
            message: "success",
            variant: "success",
            delay: 3000,
          });
        } catch (error) {
          this.handleRequestError(error);
        }
      }
      await this.fetchPendingPayBatches();
    },

  },

  mounted() {
    this.fetchPendingPayBatches();
  }
};
</script>

<style>
.pending-pay-delete {
  display: grid;
  justify-content: end;
}
</style>
