<template>
  <div>
    <div v-if="errorFetchingUsers">An error occured when fetching the user list.</div>
    <div v-else-if="fetchingUsers">Loading user list...</div>
    <div v-else-if="users.length > 0">
      <b-pagination
        align="center"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
      ></b-pagination>
      <b-input-group size="sm">
        <b-form-input
          id="filter-input"
          v-model="userFilter"
          type="search"
          placeholder="Filter"
          @input="() => {this.currentPage = 1;}"
        ></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!userFilter" @click="userFilter = ''">Clear</b-button>
        </b-input-group-append>
      </b-input-group>
      <b-table
        class="user-table"
        striped
        stacked="md"
        hover
        :fields="fields"
        :items="users"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="userFilter"
        :filter-included-fields="filterFields"
      >
        <template #cell(picture_url)="row">
          <div>
            <img class="profile-image" v-if="row.value" :src="row.value" />
            <b-icon class="profile-image" v-else icon="person-circle"></b-icon>
          </div>
        </template>

        <template #cell(roles)="row">
          <div>
            <b-badge
              pill
              v-for="role in row.item.roles"
              :key="role"
              :variant="{ admin: 'danger', rep: 'secondary', supervisor: 'warning' }[role]"
            >
              {{ role }}
            </b-badge>
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="actions">
            <router-link
              v-if="!!row.item.roles.find((r) => r === 'supervisor' || r.match(/^rep/))"
              :to="{ name: 'adminViewPay', params: { userId: row.item.user_id } }"
            >
              <b-button variant="outline-secondary" size="sm"> Pay </b-button>
            </router-link>
            <router-link :to="{ name: 'adminEditUser', params: { userId: row.item.user_id } }">
              <b-button variant="outline-secondary" size="sm"> Details </b-button>
            </router-link>
            <b-icon v-if="!row.item.active" icon="emoji-dizzy"></b-icon>
          </div>
        </template>
      </b-table>
    </div>
    <div v-else>No users found</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      errorFetchingUsers: false,
      fetchingUsers: true,
      fields: [{ key: "picture_url", label: "Picture" }, {key: "user_id", label: "ID"},  "email", "roles", "name", "actions"],
      users: [],
      userFilter: "",
      filterFields: ["email", "name", "roles", "user_id"],
      perPage: 50,
      currentPage: 1,
    };
  },
  props: { triggerListRefresh: { type: Boolean, default: false } },
  computed: {
    ...mapGetters({ adminClient: "api/adminClient" }),
    totalRows() {
      return this.users.length;
    }
  },
  mounted() {
    this.fetchUsers();
  },
  watch: {
    async triggerListRefresh(newValue) {
      if (newValue) {
        this.fetchingUsers = true;
        await this.fetchUsers();
        this.$emit("doneLoading");
        this.fetchingUsers = false;
      }
    },
  },
  methods: {
    async fetchUsers() {
      try {
        this.users = (await this.adminClient.get("/users")).data;
        this.fetchingUsers = false;
      } catch (e) {
        console.error(e);
        this.errorFetchingUsers = true;
      }
    },
  },
};
</script>

<style scoped>
.user-table td {
  word-wrap: break-word;
}

.profile-image {
  width: 60px;
  height: 60px;
}

.actions a {
  margin: 2px;
}

@media screen and (min-width: 575.98px) {
  .profile-image {
    width: 80px;
    height: 80px;
  }

  .user-table td {
    word-wrap: inherit;
  }
}
</style>
